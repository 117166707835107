import React from "react";
import {
    //  Avatar, Typography,

    Box,
} from "@mui/material";
// import { useTheme } from "@mui/material/styles";
// import { LineClamp } from "./LineClamp";
// import { DateTime } from "luxon";

import { ThreadSummaryProps } from "./ThreadList";

export const WhatsappThreadSummary: React.FC<ThreadSummaryProps> = ({ messageType, thread, expanded }) => {
    // const theme = useTheme();
    return (
        <Box width="100%">
            {/* <Box display="flex" alignItems="center" width="100%">
                <Box>
                    <Avatar
                        src={thread.Persons[0].avatarUrl}
                        sx={{ height: theme.spacing(2), width: theme.spacing(2) }}
                    >
                        {thread.Persons[0].fullName}
                    </Avatar>
                </Box>
                <Box fontWeight={thread.IsUnread ? "bold" : "regular"} ml={1}>
                    <LineClamp lines={1}>
                        {thread.Persons.map((person) => person.fullName).join(", ")} - {thread.LastMessage}
                    </LineClamp>
                </Box>
                <Box flexGrow={1} textAlign="right" mr={1}>
                    <Typography variant="caption">
                        {DateTime.fromISO(thread.LastMessageDate).toLocaleString(DateTime.DATE_SHORT)}
                    </Typography>
                </Box>
            </Box>
            <Box width="100%" display={expanded === thread.ThreadId ? "none" : "inherit"}></Box> */}
        </Box>
    );
};
