import { SelfImprovement as SelfImprovementIcon } from "@mui/icons-material";
import { Box, Typography, Button } from "@mui/material";
import { Login as LoginIcon } from "@mui/icons-material";
import React, { useContext } from "react";
import { AuthContext, IAuthContext } from "../react-oauth2-code-pkce";
import { useTheme } from "@mui/material/styles";
import { POPUP_HEIGHT, POPUP_WIDTH, POPUP_CLOSE_TIMEOUT } from "../config/constants";
import { LOGIN_URL } from "../config/servers";

export const UnauthenticatedLanding: React.FC = () => {
    const theme = useTheme();
    return (
        <Box width="100%" height={window.innerHeight - 84} display="flex" alignItems="center">
            <Box display="flex" alignItems="center" flexWrap="wrap" justifyContent="center" mx="auto">
                <Box>
                    <Box>
                        <Box display="flex" alignItems="center" mb={2}>
                            <Box mr={1}>
                                <SelfImprovementIcon sx={{ fontSize: theme.spacing(15) }} />
                            </Box>
                            <Box>
                                <Typography variant="h2">Madeon Agent</Typography>
                                <Typography variant="body1" fontStyle={"italic"}>
                                    Landing page for sales copy, e.g. screenshots, pricing, contact, etc.
                                </Typography>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};

interface LoginComponentProps {
    isPopup?: boolean;
}

export const LoginComponent: React.FC<LoginComponentProps> = ({ isPopup }) => {
    const { token, error, login }: IAuthContext = useContext(AuthContext);
    const theme = useTheme();

    if (error) {
        return <Box color={theme.palette.error.main}>{error}</Box>;
    }

    // Close the popup after 4 seconds
    if (token)
        setInterval(() => {
            window.close();
        }, POPUP_CLOSE_TIMEOUT);

    return (
        <Box
            alignItems="center"
            justifyContent="center"
            width="100%"
            display="flex"
            height={POPUP_HEIGHT - 5}
        >
            <Box textAlign="center">
                <Box>
                    <Typography variant="h5">
                        {token ? "Login successful! Closing..." : "Please login to continue"}
                    </Typography>
                </Box>
                <Box mt={2}>
                    {token ? (
                        <Button onClick={() => window.close()} variant="contained">
                            Close
                        </Button>
                    ) : (
                        <Button onClick={() => login()} variant="contained" startIcon={<LoginIcon />}>
                            Login
                        </Button>
                    )}
                </Box>
            </Box>
        </Box>
    );
};

LoginComponent.defaultProps = {
    isPopup: true,
};

export const openPopup = (url: string) => {
    // To fix issues with window.screen in multi-monitor setups, the easier option is to
    // center the pop-up over the parent window.
    const top = window.outerHeight / 2 + window.screenY - POPUP_HEIGHT / 2;
    const left = window.outerWidth / 2 + window.screenX - POPUP_WIDTH / 2;
    return window.open(
        url,
        "OAuth2 Popup",
        `height=${POPUP_HEIGHT},width=${POPUP_WIDTH},top=${top},left=${left}`
    );
};

export const LoginButton: React.FC<{}> = () => (
    <Button variant="contained" size="large" startIcon={<LoginIcon />} onClick={() => openPopup(LOGIN_URL)}>
        Login
    </Button>
);
