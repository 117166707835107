import React from "react";
import { DataGrid, GridColDef, GridRenderCellParams, GridActionsCellItem } from "@mui/x-data-grid";
import { Tooltip, Avatar, List, ListItem } from "@mui/material";
import { useGetPersonsQuery } from "../api/personApi";
import { useAppSelector } from "../redux/hooks";
import { StatusHelper } from "./StatusComponents";
import { OpenInFull as OpenInFullIcon, Male as MaleIcon, Female as FemaleIcon, Email as EmailIcon } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { useMuiGridPagination } from "../hooks/useMuiGridPagination";
import { initials } from "../utils/initials";
import { stringToColor } from "../utils/stringToColor";

export const PersonList: React.FC<{}> = () => {
    const navigate = useNavigate();
    const workspaceId = useAppSelector((state) => state.ui.workspaceId);
    const { paginationModel, handlePageChageEvent } = useMuiGridPagination();

    const {
        data: persons,
        error,
        isLoading,
    } = useGetPersonsQuery({ workspaceId, page: paginationModel.page.toString() }, { skip: !workspaceId });

    const columns: GridColDef[] = [
        { field: "fullName", headerName: "Name", width: 150 },
        {
            field: "avatarUrl",
            headerName: "Avatar",
            width: 75,
            valueGetter: (params) => ({ avatarUrl: params.row.avatarUrl, fullName: params.row.fullName }),

            renderCell: (params: GridRenderCellParams) => (
                <Avatar sx={{ bgcolor: stringToColor(params.value.fullName) }} src={params.value.avatarUrl}>
                    {initials(params.value.fullName)}
                </Avatar>
            ),
        },
        {
            field: "sex",
            headerName: "Sex",
            width: 50,
            renderCell: (params: GridRenderCellParams) =>
                params.value === "MALE" ? <MaleIcon /> : params.value === "FEMALE" ? <FemaleIcon /> : null,
        },
        { field: "koreanVisa", headerName: "Visa", width: 50 },
        { field: "nationality", headerName: "Nationality", width: 100 },
        {
            field: "birthDate",
            headerName: "DOB",
            width: 100,
            type: "date",
            valueGetter: ({ value }) => value && new Date(value),
        },
        {
            field: "emailAddresses",
            headerName: "Email Addresses",
            width: 250,
            renderCell: (params: GridRenderCellParams) => {
                const emailAddresses = params.value as string[];
                return (
                    <List dense>
                        {emailAddresses.map((emailAddress) => (
                            <ListItem key={emailAddress}>{emailAddress}</ListItem>
                        ))}
                    </List>
                );
            },
        },
        {
            field: "actions",
            type: "actions",
            headerName: "Actions",
            width: 80,
            getActions: (params: any) => [
                <GridActionsCellItem
                    icon={
                        <Tooltip title="Full Page">
                            <OpenInFullIcon />
                        </Tooltip>
                    }
                    label="Full page"
                    onClick={() => {
                        navigate(`/customer/${params.row.id}`);
                    }}
                />,
                <GridActionsCellItem
                    icon={
                        <Tooltip title="Email">
                            <EmailIcon />
                        </Tooltip>
                    }
                    label="Email"
                    onClick={() => {
                        navigate(`/customer/${params.row.id}/email`);
                    }}
                />,
            ],
        },
    ];

    if (error || !persons) return <StatusHelper isLoading={!!isLoading} error={!!error} data={!!persons} />;

    return (
        <DataGrid
            getRowId={(row) => row.id}
            rows={persons}
            columns={columns}
            onPaginationModelChange={handlePageChageEvent}
            loading={isLoading}
            paginationMode="server"
            rowCount={500}
            paginationModel={paginationModel}
            pageSizeOptions={[20]}
        />
    );
};
