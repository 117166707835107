import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { MADEON_API_SERVER } from "../config/servers";
import { User } from "../types/User";
import { WorkspaceId } from "../types/WorkspaceTypes";
import { getBearerTokenHeader } from "../utils/getToken";
const contentTypeJsonHeader = { "Content-Type": "application/json" };

interface GetMePayload {
    workspaceId: WorkspaceId;
}

export const meApi = createApi({
    reducerPath: "meApi",
    baseQuery: fetchBaseQuery({ baseUrl: MADEON_API_SERVER }),
    tagTypes: ["User"],
    endpoints: (builder) => ({
        getMe: builder.query<User, GetMePayload>({
            query: ({ workspaceId }) =>
                workspaceId && {
                    url: `/user/me`,
                    headers: {
                        ...contentTypeJsonHeader,
                        ...getBearerTokenHeader(),
                        "x-workspace-id": workspaceId,
                    },
                },
            providesTags: (result) =>
                result
                    ? [
                          { type: "User", id: result.id },
                          { type: "User", id: "LIST" },
                      ]
                    : [{ type: "User", id: "LIST" }],
        }),
    }),
});

export const { useGetMeQuery } = meApi;
