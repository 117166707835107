import React from "react";
import { Box, Popper, Paper, ClickAwayListener, MenuList, MenuItem, Grow, Button } from "@mui/material";
import { Reply, ArrowDropDown as ArrowDropDownIcon } from "@mui/icons-material";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { updateDraftRecipients } from "../redux/uiSlice";
import { useGetWorkspaceConfigQuery } from "../api/workspaceApi";

export type ResponseActionButtonsOptionIndex = 0 | 1 | 2 | 3; // 0 = No Display, 1 = Reply, 2 = Reply All, 3 = Forward
interface ResponseActionButtonsOption {
    icon: JSX.Element;
    text: string;
}
export const responseOptions: ResponseActionButtonsOption[] = [
    { icon: <></>, text: "" },
    { icon: <Reply />, text: "Reply" },
    // { icon: <ReplyAll />, text: "Reply All" },
    // { icon: <Forward />, text: "Forward" },
];

//https://mui.com/material-ui/react-button-group/#split-button

interface ResponseActionButtonsProps {
    selectedIndex: ResponseActionButtonsOptionIndex;
    setSelectedIndex: React.Dispatch<React.SetStateAction<ResponseActionButtonsOptionIndex>>;
}

export const ResponseActionButtonsInline: React.FC<ResponseActionButtonsProps> = ({
    selectedIndex,
    setSelectedIndex,
}) => {
    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef<HTMLDivElement>(null);
    const dispatch = useAppDispatch();
    const workspaceId = useAppSelector((state) => state.ui.workspaceId);
    const { data: workspaceConfig } = useGetWorkspaceConfigQuery({ workspaceId });

    const handleMenuItemClick = (
        event: React.MouseEvent<HTMLLIElement, MouseEvent>,
        index: ResponseActionButtonsOptionIndex
    ) => {
        setSelectedIndex(index);
        dispatch(updateDraftRecipients({ index, workspaceConfig }));
        setOpen(false);
    };

    const handleClose = (event: Event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
            return;
        }
        setOpen(false);
    };

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };
    return (
        <>
            <Box onClick={handleToggle} ref={anchorRef}>
                {responseOptions[selectedIndex].icon}
                <ArrowDropDownIcon />
            </Box>

            <Popper sx={{ zIndex: 1 }} open={open} anchorEl={anchorRef.current} transition disablePortal>
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{ transformOrigin: placement === "bottom" ? "center top" : "center bottom" }}
                    >
                        <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MenuList id="split-button-menu" autoFocusItem>
                                    {responseOptions.map((option, index) =>
                                        index ? (
                                            <MenuItem
                                                key={index}
                                                selected={index === selectedIndex}
                                                onClick={(event) =>
                                                    handleMenuItemClick(
                                                        event,
                                                        index as ResponseActionButtonsOptionIndex
                                                    )
                                                }
                                            >
                                                {option.text}
                                            </MenuItem>
                                        ) : null
                                    )}
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </>
    );
};

export const ResponseActionButtonsCTA: React.FC<ResponseActionButtonsProps> = ({
    selectedIndex,
    setSelectedIndex,
}) => {
    const dispatch = useAppDispatch();
    const workspaceId = useAppSelector((state) => state.ui.workspaceId);
    const { data: workspaceConfig } = useGetWorkspaceConfigQuery({ workspaceId });

    return (
        <Box mt={2} display="flex">
            {responseOptions.map((option, index) =>
                index ? (
                    <Box mr={2} key={`response-option-${index}`}>
                        <Button
                            variant="outlined"
                            startIcon={option.icon}
                            onClick={() => {
                                setSelectedIndex(index as ResponseActionButtonsOptionIndex);
                                dispatch(
                                    updateDraftRecipients({
                                        index: index as ResponseActionButtonsOptionIndex,
                                        workspaceConfig,
                                    })
                                );
                            }}
                        >
                            {option.text}
                        </Button>
                    </Box>
                ) : null
            )}
        </Box>
    );
};
