import { configureStore } from "@reduxjs/toolkit";
import counterReducer from "./counterSlice";
import { pokemonApi } from "../api/pokemon";
import { emailApi } from "../api/emailApi";
import { threadApi } from "../api/threadApi";
import { workspaceApi } from "../api/workspaceApi";
import { personApi } from "../api/personApi";
import { priorityApi } from "../api/priorityApi";
import { opportunityApi } from "../api/opportunityApi";
import { searchApi } from "../api/searchApi";
import { meApi } from "../api/meApi";
import { uiSlice } from "./uiSlice";
import { whatsappApi } from "../api/whatsappApi";

export const store = configureStore({
    reducer: {
        counter: counterReducer,
        ui: uiSlice.reducer,
        [pokemonApi.reducerPath]: pokemonApi.reducer,
        [emailApi.reducerPath]: emailApi.reducer,
        [threadApi.reducerPath]: threadApi.reducer,
        [workspaceApi.reducerPath]: workspaceApi.reducer,
        [personApi.reducerPath]: personApi.reducer,
        [priorityApi.reducerPath]: priorityApi.reducer,
        [opportunityApi.reducerPath]: opportunityApi.reducer,
        [searchApi.reducerPath]: searchApi.reducer,
        [meApi.reducerPath]: meApi.reducer,
        [whatsappApi.reducerPath]: whatsappApi.reducer,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware().concat(
            pokemonApi.middleware,
            emailApi.middleware,
            threadApi.middleware,
            workspaceApi.middleware,
            personApi.middleware,
            priorityApi.middleware,
            opportunityApi.middleware,
            searchApi.middleware,
            meApi.middleware,
            whatsappApi.middleware
        ),
    devTools: true,
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
