import { Editor } from "@ckeditor/ckeditor5-core";
import { FileLoader, UploadResponse, UploadAdapter, FileRepository } from "@ckeditor/ckeditor5-upload";
import axios from "axios";

type AttachmentId = string;
type AttachmentType = "inline" | "attachment";
export interface Attachment {
    id?: AttachmentId;
    name: string;
    url: string;
    contentType: string;
    contentId?: string;
    type?: AttachmentType;
}

export class S3UploadAdapter implements UploadAdapter {
    private loader: FileLoader;
    private editor: Editor;

    constructor(loader: FileLoader, editor: Editor) {
        this.loader = loader;
        this.editor = editor;
    }

    fireInlineImageUploadedEvent = ({ file, getUrl }: { file: File; getUrl: string }) => {
        const attachment: Attachment = {
            name: file.name,
            url: getUrl,
            contentType: file.type,
            type: "inline",
        };

        const event = new CustomEvent("inlineImageUploaded", { detail: { attachment } });
        document.dispatchEvent(event);
    };

    upload = async (): Promise<UploadResponse> => {
        const token = this.editor.config.get("token");
        const apiServer = this.editor.config.get("apiServer");
        const workspaceId = this.editor.config.get("workspaceId");

        const file = await this.loader.file;

        if (file) {
            try {
                const res1 = await axios.post(
                    `${apiServer}email/attachment/put-url`,
                    { fileName: file.name, contentType: file.type },
                    {
                        headers: {
                            Authorization: `Bearer ${token}`,
                            "x-workspace-id": workspaceId,
                            "Content-Type": "application/json",
                        },
                    }
                );
                if (res1.status !== 200) throw new Error("Error getting upload url");

                const { putUrl, getUrl }: { putUrl: string; getUrl: string } = res1.data;

                var res2Headers: {[k: string]: any} = {};
                res2Headers = {
                    "Content-Type": "application/octet-stream"
                };

                // Only attach authorization token for api server requests
                if (putUrl.startsWith(`${apiServer}`)) {
                    res2Headers = {
                        "Content-Type": "application/octet-stream",
                        Authorization: `Bearer ${token}`,
                        "x-workspace-id": workspaceId,
                    };
                }

                const res2 = await axios.put(putUrl, file, {
                    headers: res2Headers,
                    onUploadProgress: (e) => {
                        console.log(e);
                    },
                });

                if (res2.status !== 200) throw new Error("Error uploading file");

                this.fireInlineImageUploadedEvent({ file, getUrl });

                return { default: getUrl };
            } catch (err) {
                console.log(err);
            }
        }
        throw new Error("Error in client app");
    };
}

export default function S3UploadAdapterPlugin(editor: Editor) {
    editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
        return new S3UploadAdapter(loader, editor);
    };
}
