import React, { useEffect } from "react";
import { ResponseActionButtonsOptionIndex } from "../components/ResponseActionButtons";
import { Compose } from "../components/Compose";
import { Box } from "@mui/material";
import { useAppDispatch } from "../redux/hooks";
import { resetDraftEmail } from "../redux/uiSlice";
import { DefaultLayout } from "../components/DefaultLayout";
import { useNavigate } from "react-router-dom";
import { EmailComposeHeader } from "../components/EmailComposeHeader";

export const EmailCompose: React.FC<{}> = () => {
    const [selectedIndex, setSelectedIndex] = React.useState<ResponseActionButtonsOptionIndex>(1);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        dispatch(resetDraftEmail());
    }, [dispatch]);

    return (
        <DefaultLayout>
            <Box pl={2} pr={2}>
                <EmailComposeHeader />
                <Compose
                    selectedIndex={selectedIndex}
                    setSelectedIndex={setSelectedIndex}
                    postSendCallback={() => navigate(-1)}
                />
            </Box>
        </DefaultLayout>
    );
};
