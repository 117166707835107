import { useSearchParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { updatedSearchParams } from "../utils/updateSearchParams";
export const usePage = () => {
    const navigate = useNavigate();

    const [searchParams] = useSearchParams();

    const handlePageChange = (page: number) => {
        const newPath = updatedSearchParams(searchParams, { page: page.toString() });
        navigate(newPath.toString(), { replace: true });
    };

    let page: number;
    try {
        page = parseInt(searchParams.get("page") || "0");
    } catch (e) {
        page = 0;
    }
    return { page, handlePageChange };
};
