import "./App.css";
import { Provider } from "react-redux";
import { router } from "./routes/router";
import { store } from "./redux/store";
import { RouterProvider } from "react-router-dom";
import { AuthProvider } from "./react-oauth2-code-pkce";
import { config } from "./config/oauth";

const App = () => {
    return (
        <Provider store={store}>
            <AuthProvider authConfig={config}>
                <RouterProvider router={router} />
            </AuthProvider>
        </Provider>
    );
};

export default App;
