import { DefaultLayout } from "../components/DefaultLayout";
import { PersonList } from "../components/PersonList";
import { Breadcrumbs } from "../components/Breadcrumbs";

export const Persons: React.FC<{}> = () => (
    <DefaultLayout>
        <Breadcrumbs />
        <PersonList />
    </DefaultLayout>
);
