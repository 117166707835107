import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { MADEON_API_SERVER } from "../config/servers";
import { Email, EmailId, EmailPrimary, EmailPostRequestBody } from "../types/EmailTypes";
import { ThreadId } from "../types/ThreadTypes";
import { WorkspaceId } from "../types/WorkspaceTypes";
import { getBearerTokenHeader } from "../utils/getToken";
const contentTypeJsonHeader = { "Content-Type": "application/json" };

interface GetEmailPayload {
    id: EmailId;
    workspaceId: WorkspaceId;
}

interface GetEmailsPayload {
    messageIds: EmailId[];
    workspaceId: WorkspaceId;
}

interface PatchEmailPayload {
    workspaceId: WorkspaceId;
    emailPatch: Partial<Email>;
}

interface GetEmailsByThreadIdPayload {
    threadId: ThreadId;
    workspaceId: WorkspaceId;
}

interface PostEmailPayload {
    workspaceId: WorkspaceId;
    email: EmailPostRequestBody;
}

export const emailApi = createApi({
    reducerPath: "emailApi",
    baseQuery: fetchBaseQuery({ baseUrl: MADEON_API_SERVER }),
    tagTypes: ["Messages", "Message-snippets"],
    endpoints: (builder) => ({
        getEmail: builder.query<Email, GetEmailPayload>({
            query: ({ id, workspaceId }) =>
                id &&
                workspaceId && {
                    url: `/email/email-id/${id}`,
                    headers: {
                        ...contentTypeJsonHeader,
                        ...getBearerTokenHeader(),
                        "x-workspace-id": workspaceId,
                    },
                },
            providesTags: (result) =>
                result
                    ? [
                          { type: "Messages", id: result.id },
                          { type: "Messages", id: "LIST" },
                      ]
                    : [{ type: "Messages", id: "LIST" }],
        }),
        getEmails: builder.query<Email[], GetEmailsPayload>({
            query: ({ messageIds: body, workspaceId }) =>
                body &&
                workspaceId && {
                    url: `/email?messageIds=${encodeURIComponent(JSON.stringify(body))}`,
                    headers: {
                        ...contentTypeJsonHeader,
                        ...getBearerTokenHeader(),
                        "x-workspace-id": workspaceId,
                    },
                },
            providesTags: (result) =>
                result
                    ? [
                          ...result.map((email) => ({ type: "Messages" as const, id: email.id })),
                          { type: "Messages", id: "LIST" },
                      ]
                    : [{ type: "Messages", id: "LIST" }],
        }),
        patchEmail: builder.mutation<Email, PatchEmailPayload>({
            query: ({ workspaceId, emailPatch }) => {
                const { id, ...body } = emailPatch;
                return {
                    url: `/email/email-id/${id}`,
                    method: "PATCH",
                    body,
                    headers: {
                        ...contentTypeJsonHeader,
                        ...getBearerTokenHeader(),
                        "x-workspace-id": workspaceId,
                    },
                };
            },
            invalidatesTags: (result) => {
                return result ? [{ type: "Messages", id: result.id }] : [];
            },
        }),
        getEmailByThreadId: builder.query<EmailPrimary[], GetEmailsByThreadIdPayload>({
            query: ({ threadId, workspaceId }) =>
                threadId &&
                workspaceId && {
                    url: `/email/thread-id/${threadId}`,
                    headers: {
                        ...contentTypeJsonHeader,
                        ...getBearerTokenHeader(),
                        "x-workspace-id": workspaceId,
                    },
                },
            providesTags: (results) => {
                return results
                    ? [
                          ...results.map((email) => ({ type: "Message-snippets" as const, id: email.id })),
                          { type: "Message-snippets", id: "LIST" },
                      ]
                    : [{ type: "Message-snippets", id: "LIST" }];
            },
        }),
        postEmail: builder.mutation<Email, PostEmailPayload>({
            query: ({ workspaceId, email }) => ({
                url: `/email`,
                method: "POST",
                body: email,
                headers: {
                    ...contentTypeJsonHeader,
                    ...getBearerTokenHeader(),
                    "x-workspace-id": workspaceId,
                },
            }),
            invalidatesTags: [{ type: "Message-snippets", id: "LIST" }],
        }),
    }),
});

export const {
    useGetEmailsQuery,
    useGetEmailQuery,
    usePatchEmailMutation,
    useGetEmailByThreadIdQuery,
    usePostEmailMutation,
} = emailApi;
