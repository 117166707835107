//https://stackoverflow.com/questions/46559354/how-to-set-the-height-of-ckeditor-5-classic-editor/56550285

import Plugin from "@ckeditor/ckeditor5-core/src/plugin";

export class MinMaxHeightPlugin extends Plugin {
    init() {
        const minHeight: string = (this.editor.config.get("minHeight") as string) || "100px";
        const maxHeight: string = (this.editor.config.get("maxHeight") as string) || "500px";

        if (minHeight) {
            this.editor.ui.view.editable.extendTemplate({
                attributes: { style: { minHeight, maxHeight } },
            });
        }
    }
}
