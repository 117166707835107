import { OpportunityList } from "../components/OpportunityList";
import { OpportunityDrawer } from "../components/OpportunityDrawer";
import { Breadcrumbs } from "../components/Breadcrumbs";

import { DefaultLayout } from "../components/DefaultLayout";
export const Opportunity: React.FC<{}> = () => (
    <DefaultLayout>
        <Breadcrumbs />
        <OpportunityList />
        <OpportunityDrawer />
    </DefaultLayout>
);
