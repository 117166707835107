import { Avatar, Tabs, Tab, Tooltip } from "@mui/material";
import { Workspace } from "../types/WorkspaceTypes";
import { StatusHelper } from "./StatusComponents";
import { useAppSelector } from "../redux/hooks";
import { useTheme } from "@mui/material/styles";
import { newSubdomainHref } from "../config/servers";
import { useWorkspaceDomain } from "../hooks/useWorkspaceDomain";
import { stringToColor } from "../utils/stringToColor";

export const Workspaces: React.FC<{}> = () => {
    const tab = useAppSelector((state) => state.ui.workspaceTab);
    const { workspaces, isLoading, isError } = useWorkspaceDomain();
    const theme = useTheme();

    if (isLoading || isError || !!!workspaces)
        return <StatusHelper isLoading={isLoading} error={isError} data={!!workspaces} />;

    // when the tab changes, wholesale change the subdomain to the workspaceId
    // this will trigger the useEffect above to set the workspaceId since redux state is reset
    return (
        <Tabs
            aria-label="workspaces"
            value={tab}
            onChange={
                (e, newVal) =>
                    newVal < workspaces.length &&
                    (window.location.href = newSubdomainHref(workspaces[newVal].slug))
                // dispatch(setWorkspaceId(workspaces[newVal].WorkspaceId))
            }
            orientation="vertical"
            variant="scrollable"
            scrollButtons="auto"
            TabIndicatorProps={{ style: { display: "none" } }}
        >
            {workspaces.map((workspace: Workspace, index) => (
                <Tab
                    id={`workspace-panel-${index}`}
                    key={index}
                    sx={{ bgcolor: index === tab ? theme.palette.background.paper : "", minWidth: theme.spacing(5)}}
                    icon={
                        <Tooltip title={workspace.name}>
                            <Avatar
                                src={workspace?.avatarUrl}
                                alt={workspace.name}
                                sx={{
                                    width: theme.spacing(4),
                                    height: theme.spacing(4),
                                    bgcolor: stringToColor(workspace.name),
                                }}
                            />
                        </Tooltip>
                    }
                />
            ))}
        </Tabs>
    );
};
