import { DateTime } from "luxon";
// formats date from ISO 8601 to pretty format for ThreadList and Email
export const formatDate = (dateString: string): string => {
    // const now = DateTime.now();
    const localDateTime = DateTime.fromISO(dateString, { zone: "UTC", }).setZone(Intl.DateTimeFormat().resolvedOptions().timeZone);
    // console.log(Intl.DateTimeFormat().resolvedOptions().timeZone);
    // if (date.hasSame(now, "day")) return date.toLocaleString(DateTime.TIME_24_SIMPLE);
    // if (date.hasSame(now, "year")) return date.toFormat("MMM d");
    return localDateTime.toLocaleString(DateTime.DATETIME_MED);
};
