import { PersonId } from "./PersonTypes";

export type EmailId = string;
export type AttachmentId = string;

export interface Email extends EmailPrimary, EmailExtended {}

export interface EmailPrimary {
    id?: EmailId;
    subject: string;
    textBody?: string;
    to: EmailContact[];
    cc: EmailContact[];
    bcc: EmailContact[];
    from: EmailContact;
    reportedTimeCreated?: string;
    isUnread?: boolean;
}

export interface EmailExtended {
    htmlBody: string;
    htmlBodyHistory?: string;
    strippedTextReply?: string;
    attachments: Attachment[];
    metaData?: Record<string, string>;
    deliveredTime?: string;
    firstOpenedTime?: string;
    internalSenderUserDisplayName?: string;
}

export interface EmailContact {
    name: string;
    emailAddress: string;
    people?: PersonId[];
}

export const blankEmailContact: EmailContact = {
    name: "",
    emailAddress: "",
    people: [],
};

export interface Attachment {
    id?: AttachmentId;
    name: string;
    url: string;
    contentType: string;
    contentId?: string;
    type?: AttachmentType;
}

type AttachmentType = "inline" | "attachment";

export const blankDraftEmail: Email = {
    id: "",
    subject: "",
    textBody: "",
    htmlBody: "",
    strippedTextReply: "",
    reportedTimeCreated: "",
    attachments: [],
    to: [],
    cc: [],
    bcc: [],
    from: blankEmailContact,
};

export interface EmailPostRequestBody {
    from: string;
    to: EmailContact[];
    cc: EmailContact[];
    bcc: EmailContact[];
    subject: string;
    htmlBody: string;
    attachments: Attachment[];
    inReplyToEmailMessageId?: string;
}

export const ContentTypeValidSend = [
    "application/zip",
    "application/msword",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    "application/pdf",
    "application/json",
    "image/bmp",
    "image/gif",
    "image/jpeg",
    "image/png",
    "image/svg+xml",
    "image/tiff",
    "image/webp",
    "text/html",
    "text/plain",
    "text/xml",
] as const;

export type ContentTypeValidSendEnum = (typeof ContentTypeValidSend)[number];
