import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import type { OpportunityFilter, Opportunity, OpportunityId } from "../types/OpportunityTypes";
import type { WorkspaceId } from "../types/WorkspaceTypes";
import { MADEON_API_SERVER } from "../config/servers";
import { getBearerTokenHeader } from "../utils/getToken";
const contentTypeJsonHeader = { "Content-Type": "application/json" };

interface GetOpportunityPayload {
    opportunityId: OpportunityId;
    workspaceId: WorkspaceId;
}
interface PatchOpportunityPayload {
    workspaceId: WorkspaceId;
    opportunityId: OpportunityId;
    opportunity: Partial<Opportunity>;
}

export const opportunityApi = createApi({
    reducerPath: "opportunityApi",
    baseQuery: fetchBaseQuery({ baseUrl: MADEON_API_SERVER }),
    tagTypes: ["Opportunity"],
    endpoints: (builder) => ({
        getOpportunities: builder.query<Opportunity[], OpportunityFilter>({
            query: (filter) => ({
                url: `/opportunity?${new URLSearchParams(filter as any).toString()}`,
                method: "GET",
                headers: {
                    ...contentTypeJsonHeader,
                    ...getBearerTokenHeader(),
                    "x-workspace-id": filter.workspaceId,
                },
            }),
            providesTags: (result) =>
                result
                    ? [
                          ...result.map((opportunity) => ({
                              type: "Opportunity" as const,
                              id: opportunity.id,
                          })),
                          { type: "Opportunity", id: "LIST" },
                      ]
                    : [{ type: "Opportunity", id: "LIST" }],
        }),
        getOpportunity: builder.query<Opportunity, GetOpportunityPayload>({
            query: ({ opportunityId, workspaceId }) => ({
                url: `/opportunity/opportunity-id/${opportunityId}`,
                method: "GET",
                headers: {
                    ...contentTypeJsonHeader,
                    ...getBearerTokenHeader(),
                    "x-workspace-id": workspaceId,
                },
            }),
            providesTags: (result) => (result ? [{ type: "Opportunity", id: result.id }] : []),
        }),
        patchOpportunity: builder.mutation<Opportunity, PatchOpportunityPayload>({
            query: (opportunityPatch) => {
                const { workspaceId, opportunityId, opportunity } = opportunityPatch;
                return {
                    url: `/opportunity/opportunity-id/${opportunityId}`,
                    method: "PATCH",
                    body: { opportunityId, ...opportunity },
                    headers: {
                        ...contentTypeJsonHeader,
                        ...getBearerTokenHeader(),
                        "x-workspace-id": workspaceId,
                    },
                };
            },
            invalidatesTags: (result) => {
                return result ? [{ type: "Opportunity", id: result.id }] : [];
            },
        }),
    }),
});

export const {
    useGetOpportunitiesQuery,
    useLazyGetOpportunitiesQuery,
    useGetOpportunityQuery,
    usePatchOpportunityMutation,
} = opportunityApi;
