import { Button, Avatar, Tooltip } from "@mui/material";
import { openPopup } from "./LoginComponents";
import { LOGIN_URL } from "../config/servers";
import { useState, useContext } from "react";
import { AuthContext, IAuthContext } from "../react-oauth2-code-pkce";
import { Person, Login as LoginIcon, Logout as LogoutIcon } from "@mui/icons-material";
import { useTheme } from "@mui/material/styles";
import { useGetMeQuery } from "../api/meApi";
import { useAppSelector } from "../redux/hooks";
import { StatusHelper } from "./StatusComponents";

export const UserNav: React.FC<{}> = () => {
    const { token }: IAuthContext = useContext(AuthContext);
    const theme = useTheme();
    return token ? (
        <MeVatar />
    ) : (
        <Button
            variant="contained"
            sx={{
                bgcolor: theme.palette.grey[400],
                color: theme.palette.grey[900],
                "&:hover": {
                    bgcolor: theme.palette.grey[500],
                    color: theme.palette.grey[200],
                },
            }}
            onClick={() => openPopup(LOGIN_URL)}
            startIcon={<LoginIcon />}
        >
            Login
        </Button>
    );
};

const MeVatar: React.FC<{}> = () => {
    const workspaceId = useAppSelector((state) => state.ui.workspaceId);
    const { data, isLoading, error } = useGetMeQuery({ workspaceId }, { skip: !workspaceId });
    const [isHovering, setIsHovering] = useState<boolean>(false);
    const theme = useTheme();
    const { logOut }: IAuthContext = useContext(AuthContext);

    if (isLoading || error || !data)
        return <StatusHelper isLoading={!!isLoading} error={!!error} data={!!data} />;

    return (
        <Avatar
            onMouseEnter={() => setIsHovering(true)}
            onMouseLeave={() => setIsHovering(false)}
            onClick={() => logOut()}
            sx={{ bgcolor: isHovering ? theme.palette.error.main : theme.palette.success.main }}
            src={!isHovering && data?.avatarUrl ? data?.avatarUrl : ""}
        >
            {isHovering ? (
                <Tooltip title={`logout ${data?.name}`} sx={{ "&:hover": { cursor: "pointer" } }}>
                    <LogoutIcon />
                </Tooltip>
            ) : (
                <Person />
            )}
        </Avatar>
    );
};
