import { useAppSelector } from "../redux/hooks";
import { useGetOpportunityQuery, usePatchOpportunityMutation } from "../api/opportunityApi";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { useTheme } from "@mui/material/styles";
import { useParams } from "react-router-dom";
import { OpportunityId } from "../types/OpportunityTypes";

export const useOpportunity = () => {
    const theme = useTheme();
    const { opportunityId: paramOpportunityId } = useParams<{ opportunityId: OpportunityId }>();
    const navigate = useNavigate();
    const workspaceId = useAppSelector((state) => state.ui.workspaceId);
    const storeOpportunityId = useAppSelector((state) => state.ui.currentOpportunityId);
    const opportunityId = paramOpportunityId || storeOpportunityId;
    const {
        data: opportunity,
        error,
        isLoading,
    } = useGetOpportunityQuery({ opportunityId, workspaceId }, { skip: !opportunityId || !workspaceId });

    const [patchOpportunity, { isLoading: isUpdating }] = usePatchOpportunityMutation();

    const [estimatedValue, setEstimatedValue] = useState<string>(
        opportunity ? opportunity.estimatedValue : "0"
    );

    const [isEditing, setIsEditing] = useState(false);

    useEffect(() => {
        if (opportunity) {
            setEstimatedValue(opportunity.estimatedValue);
        }
    }, [opportunity]);

    const handleEditModeToggle = () => {
        setIsEditing(!isEditing);
    };

    const handleSubmit = async () => {
        patchOpportunity({
            workspaceId,
            opportunityId,
            opportunity: { estimatedValue: estimatedValue },
        });

        // Once your submit logic is implemented, you can exit edit mode here.
        setIsEditing(false);
    };

    return {
        theme,
        navigate,
        workspaceId,
        opportunityId,
        opportunity,
        error,
        isLoading,
        patchOpportunity,
        isUpdating,
        estimatedValue,
        setEstimatedValue,
        isEditing,
        setIsEditing,
        handleEditModeToggle,
        handleSubmit,
    };
};
