import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import type { Workspace, WorkspaceConfig } from "../types/WorkspaceTypes";
import { MADEON_API_SERVER } from "../config/servers";
import { getBearerTokenHeader } from "../utils/getToken";
const contentTypeJsonHeader = { "Content-Type": "application/json" };

export const workspaceApi = createApi({
    reducerPath: "workspaceApi",
    baseQuery: fetchBaseQuery({ baseUrl: MADEON_API_SERVER }),
    tagTypes: ["Workspace", "WorkspaceConfig"],
    endpoints: (builder) => ({
        getWorkspaces: builder.query<Workspace[], void>({
            query: () =>
                getBearerTokenHeader()?.Authorization && {
                    url: `/workspaces`,
                    headers: { ...contentTypeJsonHeader, ...getBearerTokenHeader() },
                },

            providesTags: (result) =>
                result
                    ? [
                          ...result.map(({ id }) => ({ type: "Workspace" as const, id })),
                          { type: "Workspace", id: "LIST" },
                      ]
                    : [{ type: "Workspace", id: "LIST" }],
        }),
        getWorkspaceConfig: builder.query<WorkspaceConfig, { workspaceId: string }>({
            query: ({ workspaceId }) =>
                workspaceId && {
                    url: `/workspace/config`,
                    headers: {
                        ...contentTypeJsonHeader,
                        ...getBearerTokenHeader(),
                        "x-workspace-id": workspaceId,
                    },
                },

            providesTags: (result) =>
                result
                    ? [
                          { type: "WorkspaceConfig", id: result.sendAsEmailAddress },
                          { type: "WorkspaceConfig", id: "LIST" },
                      ]
                    : [{ type: "WorkspaceConfig", id: "LIST" }],
        }),
    }),
});

export const { useGetWorkspacesQuery, useGetWorkspaceConfigQuery } = workspaceApi;
