import {
    Container,
    Box,
    Card,
    CardHeader,
    CardContent,
    CardActions,
    TextField,
    Button,
    Avatar,
    List,
    ListItem,
    ListItemText,
    // Badge,
    CircularProgress,
    // Typography,
} from "@mui/material";
import {
    // WhatsApp as WhatsAppIcon,
    ContactPhone as CustomerIcon,
    Email as EmailIcon,
    Save as SaveIcon,
    Edit as EditIcon,
} from "@mui/icons-material";
import { StatusHelper } from "./StatusComponents";
// import { DateTime } from "luxon";
import { useOpportunity } from "../hooks/useOpportunity";
// import { distinct } from "../utils/distinct";
import { initials } from "../utils/initials";
import { stringToColor } from "../utils/stringToColor";
import { formatDate } from "../utils/formatDate";

interface OpportunityComponentProps {
    variant: "card" | "page";
}

export const OpportunityComponent: React.FC<OpportunityComponentProps> = ({ variant }) => {
    const {
        opportunity,
        error,
        isLoading,
        theme,
        navigate,
        opportunityId,
        isUpdating,
        estimatedValue,
        setEstimatedValue,
        isEditing,
        handleEditModeToggle,
        handleSubmit,
    } = useOpportunity();

    const OpportunityCard = () => {
        return opportunity ? (
            <>
                <CardHeader
                    avatar={
                        <Avatar
                            sx={{ bgcolor: stringToColor(opportunity.person?.fullName) }}
                            src={opportunity.person?.avatarUrl}
                        >
                            {initials(opportunity.person?.fullName)}
                        </Avatar>
                    }
                    title={
                        opportunity?.person && (
                            <Box
                                onClick={() => navigate(`/customer/${opportunity.person?.id}`)}
                                sx={{ "&:hover": { cursor: "pointer" } }}
                            >
                                <>
                                    <Box position="relative" top="3px" display="inline-block" mr={1}>
                                        <CustomerIcon
                                            sx={{
                                                fontSize: theme.spacing(2),
                                                color: theme.palette.grey[600],
                                            }}
                                        />
                                    </Box>
                                    {opportunity.person?.fullName}
                                </>
                            </Box>
                        )
                    }
                    subheader={`Status: ${opportunity.status}`}
                    action={
                        <Button
                            sx={{ width: theme.spacing(10) }}
                            variant="contained"
                            color="primary"
                            onClick={isEditing ? handleSubmit : handleEditModeToggle}
                            startIcon={
                                isUpdating ? (
                                    <CircularProgress size={10} />
                                ) : isEditing ? (
                                    <SaveIcon />
                                ) : (
                                    <EditIcon />
                                )
                            }
                            disabled={isUpdating}
                        >
                            {isEditing ? "Save" : "Edit"}
                        </Button>
                    }
                />
                <CardContent>
                    <List>
                        {/* TODO: Add back owner and Clinic*/}
                        {/* <ListItem>
                            <ListItemText primary="Owner" secondary={opportunity.Owner?.fullName} />
                        </ListItem> 
                        <ListItem>
                            <ListItemText primary="Clinic" secondary={opportunity.Clinic.Name} />
                        </ListItem>*/}

                        <ListItem>
                            {isEditing ? (
                                <TextField
                                    label="Estimated Contract Value &#8361;"
                                    type="number"
                                    fullWidth
                                    value={estimatedValue}
                                    onChange={(e) => setEstimatedValue(e.target.value)}
                                    name="estimatedValue"
                                />
                            ) : (
                                <ListItemText
                                    primary="Estimated Contract Value &#8361;"
                                    secondary={`${estimatedValue.toLocaleString()}`}
                                />
                            )}
                        </ListItem>
                        <ListItem>
                            <ListItemText
                                primary="Last Message Date"
                                secondary={formatDate(opportunity.timeUpdated || "")}
                            />
                        </ListItem>
                        {/* TODO: Add back procedures */}
                        {/* <ListItem>
                            <Box>
                                <Box>
                                    <Typography>Procedures</Typography>
                                </Box>
                                <Box>
                                    <Typography variant="body2" color={theme.palette.grey[600]}>
                                        {distinct(opportunity.Procedures).join(", ")}
                                    </Typography>
                                </Box>
                            </Box>
                        </ListItem> */}
                    </List>
                </CardContent>
                <CardActions>
                    <Button
                        size="small"
                        color="primary"
                        startIcon={<EmailIcon />}
                        variant="outlined"
                        onClick={() => navigate(`/opportunity/${opportunityId}/email`)}
                    >
                        Emails
                    </Button>
                </CardActions>
            </>
        ) : null;
    };

    if (isLoading || error || !opportunity)
        return <StatusHelper isLoading={isLoading} error={!!error} data={!!opportunity} />;

    return variant === "card" ? (
        <Card>
            <OpportunityCard />
        </Card>
    ) : (
        <Box mt={2}>
            <Container maxWidth="lg">
                <OpportunityCard />
            </Container>
        </Box>
    );
};
