import React, { useMemo, useEffect } from "react";

import { useGetThreadsQuery } from "../api/threadApi";
import { useAppSelector } from "../redux/hooks";
import { StatusHelper } from "./StatusComponents";
import { useSearchParams, useParams } from "react-router-dom";
import { MessageType } from "../types/SharedTypes";
import { OpportunityId } from "../types/OpportunityTypes";
import { Thread } from "../types/ThreadTypes";
import { EmailThreadSummary } from "./EmailThreadSummary";
import { WhatsappThreadSummary } from "./WhatsappThreadSummary";
import { usePage } from "../hooks/usePage";
import { useGetOpportunityQuery } from "../api/opportunityApi";
import { useAppDispatch } from "../redux/hooks";
import Box from "@mui/material/Box";
import { PersonId } from "../types/PersonTypes";
import { setArchiveThreadCheckboxes } from "../redux/uiSlice";
import { useTheme } from "@mui/material/styles";
import { APPBAR_HEIGHT, THREADS_TOOLBAR_HEIGHT } from "../config/constants";

interface ThreadListProps {
    messageType: MessageType;
    refresh: boolean;
}

export type ResponseActionButtonsOptionIndex = 0 | 1 | 2 | 3; // 0 = No Display, 1 = Reply, 2 = Reply All, 3 = Forward

export interface ThreadSummaryProps {
    messageType: MessageType;
    thread: Thread;
    expanded: string | false;
}

export const useThreadsListData = () => {
    const workspaceId = useAppSelector((state) => state.ui.workspaceId);
    const [searchParams] = useSearchParams();

    // isAll option disables accordion expansion and navigates to ThreadComponent page on click
    const isAll: boolean = searchParams.get("isAll") === "" ? true : false;
    const { page } = usePage();
    const opportunityId: OpportunityId = useParams<{ opportunityId: string }>().opportunityId || "";
    const personId: PersonId = useParams<{ personId: string }>().personId || "";

    const { data: opportunity } = useGetOpportunityQuery(
        { workspaceId, opportunityId },
        { skip: !opportunityId || !workspaceId }
    );

    const currentThreadFilter = useAppSelector((state) => state.ui.currentThreadFilter);
    const {
        data: threads,
        error,
        isLoading,
        refetch,
    } = useGetThreadsQuery(
        {
            ...currentThreadFilter,
            workspaceId: workspaceId,
            page: page.toString(),
            personId: (opportunity && opportunity?.person?.id) || (personId !== "" && personId) || "",
        },
        { skip: !workspaceId, refetchOnMountOrArgChange: true }
    );

    return { threads, error, isLoading, refetch, isAll };
};

export const ThreadList: React.FC<ThreadListProps> = ({ messageType, refresh }) => {
    const { threads, error, isLoading, refetch, isAll } = useThreadsListData();
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (threads) {
            const archiveThreadCheckboxes = threads.map((thread) => {
                return { threadId: thread.id, checked: false };
            });

            dispatch(setArchiveThreadCheckboxes(archiveThreadCheckboxes));
        }
    }, [threads, dispatch]);

    // expand first accordion if we're not using the isAll option
    const initExpanded = useMemo(
        () => (threads && threads.length > 0 && !isAll ? threads[0].id : false),
        [isAll, threads]
    );

    useEffect(() => {
        // Trigger re-fetch when refresh changes
        if (refresh) refetch();
    }, [refresh, refetch]);

    if (isLoading || error || !threads)
        return <StatusHelper isLoading={!!isLoading} error={!!error} data={!!threads} />;

    // case 1:  Whatsapp Opportunity View - Simply display thread component from 0 item of threads returned
    // case 2:  Whatsapp isAll View - View WhatsappSummary of all threads returned with no accordion expansion
    // case 3:  Email Opportunity View - View EmailSummary of all threads returned with accordion expansion
    // case 4:  Email isAll View - View EmailSummary of all threads returned with no accordion expansion

    return (
        // added overflowY: "scroll" and height: `calc(100vh - ${APPBAR_HEIGHT + THREADS_TOOLBAR_HEIGHT}px)` to the outer Box component
        // to allow the component to scroll in the viewport while keeping the app bar and thread toolbar fixed
        <Box
            sx={{ height: `calc(100vh - ${APPBAR_HEIGHT + THREADS_TOOLBAR_HEIGHT}px)`, overflowY: "scroll" }}
        >
            {threads.map((thread, idx) => (
                <ThreadSummary
                    thread={thread}
                    initExpanded={initExpanded}
                    isAll={isAll}
                    messageType={messageType}
                    key={idx}
                />
            ))}
            {/* <ComposeNewEmail /> */}
        </Box>
    );
};

const ThreadSummary: React.FC<{
    thread: Thread;
    initExpanded: string | false;
    isAll: boolean;
    messageType: MessageType;
}> = ({ thread, isAll, messageType, initExpanded }) => {
    const theme = useTheme();
    return (
        <Box
            key={thread.id}
            sx={{ width: `calc(100vw - ${theme.spacing(12)})` }}
            p={1}
            borderBottom={1}
            borderColor={theme.palette.grey[300]}
        >
            {messageType === "Email" && (
                <EmailThreadSummary messageType={messageType} thread={thread} expanded={false} />
            )}
            {messageType === "Whatsapp" && isAll && (
                <WhatsappThreadSummary messageType={messageType} thread={thread} expanded={false} />
            )}
        </Box>
    );
};
