import { EmailContact } from "./EmailTypes";
import { WhatsappContact } from "./WhatsappTypes";
export type PersonId = string;

export interface Person {
    id: PersonId;
    fullName: string;
    firstName: string;
    lastName: string;
    sex?: "MALE" | "FEMALE";
    nationality?: string;
    birthDate?: string; // format: date-time
    avatarUrl?: string;
    koreanVisa?: KoreanVisaEnum;
}
export const blankPerson: Person = {
    id: "",
    fullName: "",
    firstName: "",
    lastName: "",
    sex: "MALE",
    nationality: "",
    birthDate: "",
    avatarUrl: "",
    koreanVisa: "NONE",
};

export interface PersonContacts extends Person {
    emailAddresses: string[];
}
export type Contact = EmailContact | WhatsappContact;

export const blankPersonContacts: PersonContacts = {
    ...blankPerson,
    emailAddresses: [],
};

export const KoreanVisaEnumValues = [
    "NONE",
    "A",
    "B",
    "C",
    "D",
    "E",
    "F",
    "G",
    "H",
    "M",
    "T",
    "OTHER",
    "MILITARY",
    "CITIZEN",
] as const;

export type KoreanVisaEnum = (typeof KoreanVisaEnumValues)[number];
