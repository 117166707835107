import React, { useState } from "react";
import { DefaultLayout } from "../components/DefaultLayout";
import { ThreadList } from "../components/ThreadList";
import { MessageType } from "../types/SharedTypes";
import { Box } from "@mui/material";
import { Pagination } from "../components/Pagination";
import { ThreadsButtonGroup } from "../components/ThreadsButtonGroup";

interface ThreadsProps {
    messageType: MessageType;
}

export const Threads: React.FC<ThreadsProps> = ({ messageType }) => {
    const [refresh, setRefresh] = useState(false);
    const handleRefresh = () => setRefresh(!refresh);

    return (
        <DefaultLayout pagination>
            <Box display="flex">
                <Box>
                    <ThreadsButtonGroup onRefresh={handleRefresh} />
                </Box>
                <Box flexGrow={1}></Box>
                <Box mr={3}>
                    <Pagination />
                </Box>
            </Box>

            <ThreadList messageType={messageType || "Email"} refresh={refresh} />
        </DefaultLayout>
    );
};
