import React from "react";
import { Typography, Chip, Box, Checkbox } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { MessageTypeIcon } from "./MessageTypeIcon";
import { LineClamp } from "./LineClamp";
// import { DateTime } from "luxon";
import { formatDate } from "../utils/formatDate";
import { useAppSelector, useAppDispatch } from "../redux/hooks";
import { useNavigate } from "react-router-dom";
import { setArchiveThreadCheckbox } from "../redux/uiSlice";

import { ThreadSummaryProps } from "./ThreadList";

export const EmailThreadSummary: React.FC<ThreadSummaryProps> = ({ messageType, thread, expanded }) => {
    const theme = useTheme();
    const archiveThreadCheckboxes = useAppSelector((state) => state.ui.archiveThreadCheckboxes);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    return (
        <Box onClick={() => navigate(`/threads/${thread.id}`)} sx={{ cursor: "pointer" }}>
            <Box display="flex" alignItems="center">
                <Box display="inline" onClick={(e) => e.stopPropagation()}>
                    <Checkbox
                        size="small"
                        checked={
                            archiveThreadCheckboxes.find((checkStatus) => checkStatus.threadId === thread.id)
                                ?.checked || false
                        }
                        onChange={(e) => {
                            dispatch(
                                setArchiveThreadCheckbox({ threadId: thread.id, checked: e.target.checked })
                            );
                        }}
                    />
                </Box>
                <Box>
                    {thread?.messageCount && (
                        <Chip label={thread.messageCount} variant="outlined" size="small" />
                    )}
                </Box>
                <Box>
                    <Box position="relative" top={theme.spacing(0.2)} mx={1}>
                        <MessageTypeIcon messageType={"Email"} />
                    </Box>
                </Box>

                <Box flexGrow={1}>
                    <LineClamp lines={1} sx={{ fontSize: theme.spacing(1.8) }}>
                        {thread.subject}
                    </LineClamp>
                </Box>
                <Box textAlign="right" sx={{ minWidth: theme.spacing(16) }}>
                    <Typography variant="caption">
                        {formatDate(thread.lastMessageTimeCreated || "")}
                    </Typography>
                </Box>
            </Box>
            <Box display={expanded === thread.id ? "none" : "inherit"}>
                <LineClamp
                    lines={1}
                    sx={{ fontSize: theme.spacing(1.5), color: theme.palette.grey[600] }}
                >{`${thread?.lastMessageSnippet}`}</LineClamp>
            </Box>
        </Box>
    );
};
