import React, { useEffect, useRef } from "react";
import DOMPurify from "dompurify";
import { removeHtmlTags } from "../utils/removeHtmlTags";

interface SafeHTMLProps {
    html: string;
}

export const SafeHTML: React.FC<SafeHTMLProps> = ({ html }) => {
    const containerRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (containerRef.current) {
            // Sanitize the HTML content and remove any unwanted tags
            containerRef.current.innerHTML = DOMPurify.sanitize(removeHtmlTags(html));
        }
    }, [html]);

    return <div ref={containerRef} />;
};
