import React, { useState } from "react";
import { Box, IconButton, Checkbox, CircularProgress, Tooltip } from "@mui/material";
import { Refresh, Archive, AddCircleOutlineOutlined } from "@mui/icons-material";
import { useTheme } from "@mui/material/styles";
import { setAllArchiveThreadCheckboxesToTrue, setAllArchiveThreadCheckboxesToFalse } from "../redux/uiSlice";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { usePatchThreadsMutation } from "../api/threadApi";
import { useNavigate } from "react-router-dom";

export interface ThreadsButtonGroupProps {
    onRefresh: () => void;
}

export const ThreadsButtonGroup: React.FC<ThreadsButtonGroupProps> = ({ onRefresh }) => {
    const theme = useTheme();
    const [allChecked, setAllChecked] = useState(false);
    const dispatch = useAppDispatch();
    const { archiveThreadCheckboxes, workspaceId } = useAppSelector((state) => state.ui);
    const navigate = useNavigate();

    const [patchThreadsMutation, { isLoading: isArchiveLoading }] = usePatchThreadsMutation();
    return (
        <Box m={1}>
            <Box display="flex" width={theme.spacing(20)} ml={-0.4} justifyContent="space-evenly">
                <Checkbox
                    size="small"
                    checked={allChecked}
                    onChange={() => {
                        setAllChecked(!allChecked);
                        if (allChecked) {
                            dispatch(setAllArchiveThreadCheckboxesToFalse());
                        } else {
                            dispatch(setAllArchiveThreadCheckboxesToTrue());
                        }
                    }}
                />
                <IconButton onClick={onRefresh}>
                    <Tooltip title="Refresh">
                        <Refresh fontSize="small" />
                    </Tooltip>
                </IconButton>
                <IconButton
                    disabled={
                        // enabled if any checkbox is checked
                        archiveThreadCheckboxes.some((checkbox) => checkbox.checked) ? false : true
                    }
                    onClick={async () => {
                        const threadIds = archiveThreadCheckboxes
                            .filter((checkbox) => checkbox.checked)
                            .map((checkbox) => checkbox.threadId);
                        await patchThreadsMutation({ threadIds, workspaceId });
                        onRefresh();
                        setAllChecked(false);
                    }}
                >
                    {isArchiveLoading ? (
                        <CircularProgress size="1.3rem" />
                    ) : (
                        <Tooltip title="Archive">
                            <Archive fontSize="small" />
                        </Tooltip>
                    )}
                </IconButton>
                <IconButton onClick={() => navigate("/email/compose")}>
                    <Tooltip title="Compose new email">
                        <AddCircleOutlineOutlined fontSize="small" />
                    </Tooltip>
                </IconButton>
            </Box>
        </Box>
    );
};
