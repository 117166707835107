import { Box, Button, Typography } from "@mui/material";
import { ArrowBack } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

interface EmailComposeHeaderProps {}

export const EmailComposeHeader: React.FC<EmailComposeHeaderProps> = () => {
    const navigate = useNavigate();
    return (
        <Box display="flex" mt={1} mb={2} alignItems="center">
            <Button
                size="small"
                variant="outlined"
                onClick={() => navigate(-1)}
                startIcon={<ArrowBack />}
                sx={{
                    color: "grey",
                    borderColor: "grey.500",
                    "&:hover": {
                        borderColor: "grey.700",
                    },
                }}
            >
                Back
            </Button>
            <Box ml={2}>
                <Typography variant="body1" color="grey.600">
                    Compose new email
                </Typography>
            </Box>
        </Box>
    );
};
