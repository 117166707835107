import { createBrowserRouter } from "react-router-dom";
import { Thread } from "./Thread";
import { Threads } from "./Threads";
import { Opportunity } from "./Opportunity";
import { OpportunityStandalone } from "./OpportunityStandalone";
import { Persons } from "./Persons";
import { PersonStandalone } from "./PersonStandalone";
import { EmailCompose } from "./EmailCompose";
import { BreadCrumb } from "../components/Breadcrumbs";
import { Login } from "./Login";
import { Sandbox } from "./Sandbox";

import {
    Email as EmailIcon,
    WhatsApp as WhatsAppIcon,
    ContactPhone as CustomerIcon,
    Flag as OpportunityIcon,
} from "@mui/icons-material";

const threadRoutePartial = {
    handle: {
        crumb: () => <BreadCrumb to="/threads?isAll" icon={<EmailIcon />} crumbText="Email Threads" />,
    },
    children: [
        { index: true, element: <Threads messageType="Email" /> },
        {
            path: ":threadId",
            element: <Thread messageType="Email" />,
            handle: {
                crumb: ({ params }: { params: any }) => (
                    <BreadCrumb
                        to={`/threads/${params.threadId}`}
                        icon={<EmailIcon />}
                        crumbText={params.threadId}
                    />
                ),
            },
            children: [
                {
                    path: "email",
                    element: <Thread messageType="Email" />,
                    handle: {
                        crumb: ({ params }: { params: any }) => (
                            <BreadCrumb
                                to={`/threads/${params.threadId}`}
                                icon={<EmailIcon />}
                                crumbText={params.threadId}
                            />
                        ),
                    },
                    children: [
                        {
                            index: true,
                            element: <Thread messageType="Email" />,
                        },
                        {
                            path: ":emailId",
                            element: <Thread messageType="Email" />,
                            handle: {
                                crumb: ({ params }: { params: any }) => (
                                    <BreadCrumb
                                        to={`/threads/${params.threadId}/email/${params.emailId}`}
                                        icon={<EmailIcon />}
                                        crumbText={params.emailId}
                                    />
                                ),
                            },
                        },
                    ],
                },
            ],
        },
    ],
};

export const router = createBrowserRouter([
    {
        path: "/",
        ...threadRoutePartial,
    },
    {
        path: "/opportunity",
        handle: {
            crumb: () => (
                <BreadCrumb to="/opportunity" icon={<OpportunityIcon />} crumbText="Opportunities" />
            ),
        },
        children: [
            {
                index: true,
                element: <Opportunity />,
            },
            {
                path: ":opportunityId",
                handle: {
                    crumb: ({ params }: { params: any }) => (
                        <BreadCrumb
                            to={`/opportunity/${params.opportunityId}`}
                            icon={<OpportunityIcon />}
                            crumbText={params.opportunityId}
                        />
                    ),
                },
                children: [
                    {
                        index: true,
                        element: <OpportunityStandalone />,
                    },
                    {
                        path: "email",
                        element: <Threads messageType="Email" />,
                        handle: {
                            crumb: ({ params }: { params: any }) => (
                                <BreadCrumb
                                    to={`/opportunity/${params.opportunityId}/email`}
                                    icon={<EmailIcon />}
                                    crumbText="emails"
                                />
                            ),
                        },
                    },
                    {
                        path: "whatsapp",
                        element: <Threads messageType="Whatsapp" />,
                        handle: {
                            crumb: ({ params }: { params: any }) => (
                                <BreadCrumb
                                    to={`/opportunity/${params.opportunityId}/whatsapp`}
                                    icon={<WhatsAppIcon />}
                                    crumbText="whatsapp"
                                />
                            ),
                        },
                    },
                ],
            },
        ],
    },
    {
        path: "/threads",
        ...threadRoutePartial,
    },
    {
        path: "/whatsapp",
        handle: {
            crumb: () => <BreadCrumb to="/whatsapp?isAll" icon={<WhatsAppIcon />} crumbText="whatsapp" />,
        },
        children: [
            { index: true, element: <Threads messageType="Whatsapp" /> },
            {
                path: ":threadId",
                element: <Thread messageType="Whatsapp" />,
                handle: {
                    crumb: ({ params }: { params: any }) => (
                        <BreadCrumb
                            to={`/whatsapp/${params.threadId}`}
                            icon={<WhatsAppIcon />}
                            crumbText={params.threadId}
                        />
                    ),
                },
            },
        ],
    },
    {
        path: "/customer",
        handle: {
            crumb: () => <BreadCrumb to="/customer" icon={<CustomerIcon />} crumbText="Customers" />,
        },
        children: [
            {
                index: true,
                element: <Persons />,
            },
            {
                path: ":personId",
                // element: <PersonStandalone />,
                handle: {
                    crumb: ({ params }: { params: any }) => (
                        <BreadCrumb
                            to={`/customer/${params.personId}`}
                            crumbText={params.personId}
                            icon={<CustomerIcon />}
                        />
                    ),
                },
                children: [
                    {
                        index: true,
                        element: <PersonStandalone />,
                    },
                    {
                        path: "email",
                        element: <Threads messageType="Email" />,
                        handle: {
                            crumb: ({ params }: { params: any }) => (
                                <BreadCrumb
                                    to={`/customer/${params.personId}/email`}
                                    icon={<EmailIcon />}
                                    crumbText="emails"
                                />
                            ),
                        },
                    },
                ],
            },
        ],
    },
    {
        path: "/login",
        element: <Login />,
    },
    {
        path: "/sandbox",
        element: <Sandbox />,
    },
    {
        path: "/email/compose",
        element: <EmailCompose />,
    },
]);
