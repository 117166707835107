// modeled on https://codesandbox.io/p/sandbox/react-mui-file-upload-lgqwn

import React from "react";
import { Box, Typography } from "@mui/material";
import { CloudUpload as CloudUploadIcon } from "@mui/icons-material";
import clsx from "clsx";

export type FileUploadProps = {
    accept: string;
    hoverLabel?: string;
    dropLabel?: string;
    width?: string;
    height?: string;
    backgroundColor?: string;
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    onDrop: (event: React.DragEvent<HTMLElement>) => void;
};

export const FileUpload: React.FC<FileUploadProps> = ({
    accept,
    hoverLabel = "Click or drag to upload file",
    dropLabel = "Drop file here",
    width = "300px",
    height = "100px",
    backgroundColor = "#fff",
    onChange,
    onDrop,
}) => {
    const [labelText, setLabelText] = React.useState<string>(hoverLabel);
    const [isDragOver, setIsDragOver] = React.useState<boolean>(false);
    const stopDefaults = (e: React.DragEvent) => {
        e.stopPropagation();
        e.preventDefault();
    };
    const dragEvents = {
        onDragEnter: (e: React.DragEvent) => {
            stopDefaults(e);
            setIsDragOver(true);
            setLabelText(dropLabel);
        },
        onDragLeave: (e: React.DragEvent) => {
            stopDefaults(e);
            setIsDragOver(false);
            setLabelText(hoverLabel);
        },
        onDragOver: stopDefaults,
        onDrop: (e: React.DragEvent<HTMLElement>) => {
            stopDefaults(e);
            setLabelText(hoverLabel);
            setIsDragOver(false);

            onDrop(e);
        },
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        onChange(event);
    };

    const rootClass = clsx({
        ...{
            display: "flex",
            cursor: "pointer",
            textAlign: "center",

            "&:hover p,&:hover svg,& img": {
                opacity: 1,
            },
            "& p, svg": {
                opacity: 0.4,
            },
            "&:hover img": {
                opacity: 0.3,
            },
        },
        ...(isDragOver
            ? {
                  "& img": {
                      opacity: 0.3,
                  },
                  "& p, svg": {
                      opacity: 1,
                  },
              }
            : {}),
    });

    return (
        <>
            <input
                onChange={handleChange}
                accept={accept}
                style={{ display: "none" }}
                id="file-upload"
                type="file"
            />

            <label htmlFor="file-upload" {...dragEvents} className={rootClass}>
                <Box width={width} height={height} bgcolor={backgroundColor} sx={{ pointerEvents: "none" }}>
                    <Box
                        height={height}
                        width={width}
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                            flexDirection: "column",
                            alignItems: "center",
                            position: "absolute",
                        }}
                    >
                        <CloudUploadIcon fontSize="large" />
                        <Typography>{labelText}</Typography>
                    </Box>
                </Box>
            </label>
        </>
    );
};
