interface Replacement {
    find: string;
    replace: string;
}

export function replaceInHaystack(haystack: string, needles: Replacement[]): string {
    let result = haystack;

    for (const needle of needles) {
        result = result.split(needle.find).join(needle.replace);
    }

    return result;
}
