// Define a type for the new search params
type NewSearchParams = Record<string, string>;

// Function to merge search parameters and construct a new URL
export const updatedSearchParams = (
    currentSearchParams: URLSearchParams,
    newSearchParams: NewSearchParams
): string => {
    // Merge existing search params with new ones
    const updatedSearchParams = new URLSearchParams(currentSearchParams.toString());
    Object.entries(newSearchParams).forEach(([key, value]) => {
        updatedSearchParams.set(key, value);
    });

    // Construct the new URL
    const newUrl = `${window.location.pathname}?${updatedSearchParams.toString()}`;
    return newUrl;
};
