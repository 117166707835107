import { DefaultLayout } from "../components/DefaultLayout";
import { ThreadComponent } from "../components/ThreadComponent";
import { MessageType } from "../types/SharedTypes";
import { ThreadButtonGroup } from "../components/ThreadButtonGroup";
export const Thread: React.FC<{ messageType: MessageType }> = ({ messageType }) => (
    <DefaultLayout>
        <ThreadButtonGroup />
        <ThreadComponent />
    </DefaultLayout>
);
