import { Autocomplete, TextField } from "@mui/material";
import { useSearchPersonQuery } from "../api/searchApi";
import React, { useEffect, useState } from "react";
import { useTheme } from "@mui/material/styles";
import { useDebounce } from "../hooks/useDebounce";
import { useAppSelector } from "../redux/hooks";
import { EmailContact } from "../types/EmailTypes";
import { CustomOption, CustomTags } from "./PersonInputComponents";
import { PERSON_INPUT_DEBOUNCE_TIME_MS } from "../config/constants";
import { Search } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

export interface AutocompleteOption {
    label: string;
    emailContact: EmailContact;
    key: string;
    id: string;
}

export interface GlobalSearchInputProps {}

export const GlobalSearchInput: React.FC<GlobalSearchInputProps> = (props) => {
    const theme = useTheme();
    const [inputValue, setInputValue] = useState<string>("");
    const [value, setValue] = useState<AutocompleteOption[]>([]);
    const debouncedInputValue = useDebounce(inputValue, PERSON_INPUT_DEBOUNCE_TIME_MS);
    const [options, setOptions] = useState<AutocompleteOption[]>([]);
    const workspaceId = useAppSelector((state) => state.ui.workspaceId);
    const { data: persons } = useSearchPersonQuery(
        {
            queryString: debouncedInputValue,
            workspaceId,
        },
        { skip: !workspaceId || !debouncedInputValue }
    );

    // const dispatch = useAppDispatch();
    const navigate = useNavigate();

    // Set the options for the autocomplete based on the persons returned from the search query
    useEffect(() => {
        if (!persons) return;

        const flatPersons: AutocompleteOption[] = [];
        persons.forEach((person) => {
            person.emailAddresses.forEach((email) => {
                flatPersons.push({
                    label: `${person.fullName} (${email})`,
                    emailContact: { name: person.fullName, emailAddress: email, people: [] },
                    id: person.id,
                    key: person.id,
                });
            });
        });

        // Add add email option if the input value is a valid email
        // if (inputValue.length > 0 && validEmail(inputValue)) {
        //     flatPersons.push({
        //         label: `Add email : ${inputValue}`,
        //         emailContact: {
        //             ...blankEmailContact,
        //             name: inputValue,
        //             emailAddress: inputValue
        //         },
        //         id: "not-created",
        //         key: "unique"
        //     });
        // }

        setOptions(flatPersons);
    }, [persons, inputValue]);

    const handleChange = (
        event: React.SyntheticEvent<Element, Event>,
        newValue: readonly AutocompleteOption[]
    ) => {
        if (newValue) {
            // let mutableNewValue = [...newValue];

            // TODO:  Figure out what this code is doing and revise
            // const newEmailContactIndex = newValue.findIndex(
            //     (recipient) => recipient.id === "contactId-create-new"
            // );

            // if (newEmailContactIndex >= 0) {
            //     const newEmailContact: AutocompleteOption = newValue[newEmailContactIndex];
            //     mutableNewValue[newEmailContactIndex].label = newEmailContact.label.substring(12);
            //     mutableNewValue[
            //         newEmailContactIndex
            //     ].id = `contactId-create-${newEmailContact.label.substring(12)}`;
            // }

            // Clears value so that dispatch-effect can set Value from scratch
            setValue([]);

            navigate(`/customer/${newValue[0].id}/email`);

            // Warning:  This moves all the email recipients from CC to TO
            // dispatch(setDraftEmailToRecipients(mutableNewValue.map((recipient) => recipient.emailContact)));
        }
        return;
    };

    return (
        <Autocomplete
            fullWidth
            value={value}
            onChange={handleChange}
            inputValue={inputValue}
            onInputChange={(event, newInputValue) => setInputValue(newInputValue)}
            options={options}
            getOptionLabel={(option) => option.label}
            multiple
            // sx={{ maxWidth: theme.spacing(100) }}
            size="small"
            renderTags={(value, getTagProps) => <CustomTags value={value} getTagProps={getTagProps} />}
            // renderInput={(params) => <CustomInput params={params} isLoading={isLoading} {...props} />}
            renderInput={(params) => (
                <TextField
                    {...params}
                    variant="outlined"
                    margin="dense"
                    fullWidth
                    placeholder={`Search`}
                    InputProps={{
                        ...params.InputProps,
                        startAdornment: <Search />,
                        endAdornment: null,
                        style: { backgroundColor: theme.palette.background.paper },
                    }}
                />
            )}
            renderOption={(props, recipient) => <CustomOption props={props} recipient={recipient} />}
        />
    );
};
