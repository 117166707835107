import { useMemo } from "react";
import { useAppSelector } from "../redux/hooks";
import { useGetOpportunitiesQuery } from "../api/opportunityApi";
import { StatusHelper } from "./StatusComponents";
import {
    // GridRenderCellParams,
    DataGrid,
    GridColDef,
    GridEventListener,
    GridActionsCellItem,
    GridRenderCellParams,
} from "@mui/x-data-grid";
import { setOpportunityId, setDrawerOpen } from "../redux/uiSlice";
import { useAppDispatch } from "../redux/hooks";
import {
    // Badge,
    Tooltip,
    Avatar,
    Box,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
// import { distinct } from "../utils/distinct";
import { initials } from "../utils/initials";
import { stringToColor } from "../utils/stringToColor";

import {
    Email as EmailIcon,
    WhatsApp as WhatsAppIcon,
    OpenInFull as OpenInFullIcon,
    ContactPhone as CustomerIcon,
} from "@mui/icons-material";
import { useMuiGridPagination } from "../hooks/useMuiGridPagination";

export const OpportunityList: React.FC<{}> = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { paginationModel, handlePageChageEvent } = useMuiGridPagination();

    const { workspaceId } = useAppSelector((state) => state.ui);
    const {
        data: opportunities,
        error,
        isLoading,
    } = useGetOpportunitiesQuery(
        {
            workspaceId,
            page: paginationModel.page.toString(),
        },
        { skip: !workspaceId }
    );

    const columns: GridColDef[] = useMemo(
        () => [
            {
                field: "person.avatarUrl",
                headerName: "Avatar",
                width: 225,
                valueGetter: (params) => ({
                    avatarUrl: params.row.person.avatarUrl,
                    fullName: params.row.person.fullName,
                }),
                renderCell: (params: GridRenderCellParams) => (
                    <>
                        <Avatar
                            sx={{ bgcolor: stringToColor(params.value.fullName) }}
                            src={params.value.avatarUrl}
                        >
                            {initials(params.value.fullName)}
                        </Avatar>
                        <Box ml={1}>{params.value.fullName}</Box>
                    </>
                ),
            },

            { field: "status", headerName: "Status", width: 200, valueGetter: (params) => params.row.status },
            {
                field: "estimatedValue",
                headerName: "Contract Val ₩",
                width: 130,
                valueFormatter: (params) => `${parseInt(params.value).toLocaleString()}`,
            },
            // {
            //     field: "Owner",
            //     headerName: "Owner",
            //     width: 130,
            //     valueGetter: (params) => params.row.Owner.Name,
            // },
            // {
            //     field: "Clinic",
            //     headerName: "Clinic",
            //     width: 150,
            //     valueGetter: (params) => params.row.Clinic.Name,
            // },

            {
                field: "timeUpdated",
                headerName: "Last Message",
                width: 100,
                type: "date",
                valueGetter: ({ value }) => value && new Date(value),
            },
            // {
            //     field: "Procedures",
            //     headerName: "Procedures",
            //     width: 250,
            //     valueGetter: (params: GridRenderCellParams) =>
            //         params.value && params.value.length > 0 ? distinct(params.value).join(", ") : "",
            // },
            {
                field: "actions",
                type: "actions",
                headerName: "Actions",
                width: 120,
                getActions: (params: any) => [
                    <GridActionsCellItem
                        icon={<OpenInFullIcon />}
                        label="Full page"
                        showInMenu
                        onClick={() => {
                            navigate(`/opportunity/${params.row.id}`);
                        }}
                    />,
                    <GridActionsCellItem
                        icon={
                            <Tooltip title="Email">
                                <EmailIcon />
                            </Tooltip>
                        }
                        label="Email"
                        onClick={() => navigate(`/opportunity/${params.row.id}/email?noPagination`)}
                    />,
                    <GridActionsCellItem
                        icon={<CustomerIcon />}
                        label="Customer page"
                        showInMenu
                        onClick={() => navigate(`/customer/${params.row.person.id}`)}
                    />,
                    <GridActionsCellItem
                        disabled
                        icon={<WhatsAppIcon />}
                        label="WhatsApp"
                        // onClick={() => navigate(`/opportunity/${params.row.id}/whatsapp`)}
                    />,
                ],
            },
        ],
        [navigate]
    );

    const handleEvent: GridEventListener<"rowClick"> = (params, event, details) => {
        dispatch(setOpportunityId(params.row.id));
        dispatch(setDrawerOpen(true));
    };

    if (error || !opportunities)
        return <StatusHelper isLoading={!!isLoading} error={!!error} data={!!opportunities} />;

    return (
        <DataGrid
            rows={opportunities}
            getRowId={(row) => row.id}
            columns={columns}
            onRowClick={handleEvent}
            pageSizeOptions={[20]}
            onPaginationModelChange={handlePageChageEvent}
            loading={isLoading}
            paginationMode="server"
            rowCount={500}
            paginationModel={paginationModel}
        />
    );
};
