import React, { useState } from "react";
import {
    Avatar,
    Box,
    Chip,
    Tooltip,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Menu,
    Typography,
    IconButton,
    Grid,
    Divider,
} from "@mui/material";
import { useGetEmailQuery } from "../api/emailApi";
import { useTheme } from "@mui/material/styles";
import { LineClamp } from "./LineClamp";
import {
    ExpandMore as ExpandMoreIcon,
    AttachFile as AttachFileIcon,
    MoveToInbox as MoveToInboxIcon,
    Drafts as DraftsIcon,
    SupervisedUserCircle as SupervisedUserCircleIcon,
    MoreHoriz as MoreHorizIcon,
} from "@mui/icons-material";
import { StatusHelper } from "./StatusComponents";
// import parse from "html-react-parser";
import { formatDate } from "../utils/formatDate";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { setCurrentMessageId, setCurrentEmail } from "../redux/uiSlice";
import { useEffect } from "react";
import { Compose } from "./Compose";
import { ResponseActionButtonsOptionIndex, ResponseActionButtonsCTA } from "./ResponseActionButtons";
import { EmailId, EmailPrimary } from "../types/EmailTypes";
// import { convertPlainTextToHtml } from "../utils/converPlainTextToHtml";
import { initials } from "../utils/initials";
import { stringToColor } from "../utils/stringToColor";
import { AttachmentsList } from "./AttachmentsList";
import { SafeHTML } from "./SafeHTML";

interface EmailProps {
    id: EmailId;
    initiallyExpanded?: boolean;
    isLast?: boolean;
    emailSnippet: EmailPrimary;
}

export const Email: React.FC<EmailProps> = ({ id, initiallyExpanded, isLast, emailSnippet }) => {
    const { currentMessageId } = useAppSelector((state) => state.ui);
    const [expandedMessage, setExpandedMessage] = useState<boolean>(!!initiallyExpanded);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const dispatch = useAppDispatch();

    const [selectedIndex, setSelectedIndex] = React.useState<ResponseActionButtonsOptionIndex>(0);

    const handleAccordionChange = () => {
        if (!(expandedMessage && Boolean(anchorEl))) {
            dispatch(setCurrentMessageId(id));
            setExpandedMessage(!expandedMessage);
        }
    };

    useEffect(() => {
        if (id === currentMessageId && emailSnippet) dispatch(setCurrentEmail(emailSnippet));
    }, [currentMessageId, emailSnippet, dispatch, id]);

    return (
        <Box>
            <Accordion
                expanded={expandedMessage}
                onChange={handleAccordionChange}
                key={`${emailSnippet.id}`}
                sx={{
                    boxShadow: "none",
                }}
                disableGutters={true}
                elevation={0}
                square
                TransitionProps={{ timeout: 0 }}
            >
                {/* <ExpandMoreIcon /> */}
                <AccordionSummary expandIcon={null}>
                    {expandedMessage ? (
                        <EmailSummaryExpanded id={id} anchorEl={anchorEl} setAnchorEl={setAnchorEl} />
                    ) : (
                        <EmailSummaryCollapsed emailSnippet={emailSnippet} />
                    )}
                </AccordionSummary>
                {expandedMessage && (
                    <AccordionDetails>
                        <EmailDetailsExpanded
                            id={id}
                            isLast={isLast}
                            selectedIndex={selectedIndex}
                            setSelectedIndex={setSelectedIndex}
                        />
                    </AccordionDetails>
                )}
            </Accordion>
            <Divider></Divider>
        </Box>
    );
};

const EmailSummaryCollapsed: React.FC<{ emailSnippet: EmailPrimary }> = ({ emailSnippet }) => {
    const theme = useTheme();
    return (
        <Box
            display="flex"
            justifyContent={"space-between"}
            width="100%"
            sx={{ fontWeight: emailSnippet?.isUnread ? "bold" : "normal" }}
        >
            <Grid container spacing={2}>
                <Grid item>
                    <Avatar
                        alt={emailSnippet?.from?.name}
                        sx={{
                            width: theme.spacing(3),
                            height: theme.spacing(3),
                            bgcolor: stringToColor(emailSnippet?.from?.name),
                        }}
                    >
                        {initials(emailSnippet?.from?.name)}
                    </Avatar>
                </Grid>
                <Grid item xs={12} sm container>
                    <Grid item container direction="column" spacing={1}>
                        <Grid item>
                            <Box mr={2} flex={`0 0 ${theme.spacing(20)}`}>
                                <LineClamp lines={1}>{emailSnippet.from.name}</LineClamp>
                            </Box>
                        </Grid>
                        <Grid item>
                            <LineClamp
                                lines={1}
                                sx={{ fontSize: theme.spacing(1.5), color: theme.palette.grey[800] }}
                            >{`${emailSnippet.textBody}`}</LineClamp>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item>
                    <Box flexGrow={1} textAlign="right" mr={1} minHeight={"27px"} alignItems="normal">
                        {/* TODO:  Add back attachment icon */}
                        {/* {email?.attachments && email.attachments.length > 0 && (
                            <Box display="inline-block" position="relative" top={3}>
                                <AttachFileIcon fontSize="small" />
                            </Box>
                        )} */}
                        <Typography variant="caption">
                            {formatDate(emailSnippet?.reportedTimeCreated || "")}
                        </Typography>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
};

const EmailDetailsMenu: React.FC<{
    anchorEl: null | HTMLElement;
    setAnchorEl: React.Dispatch<React.SetStateAction<HTMLElement | null>>;
    email: EmailPrimary;
}> = ({ anchorEl, setAnchorEl, email }) => {
    const theme = useTheme();

    return (
        <Menu open={Boolean(anchorEl)} anchorEl={anchorEl} onClose={() => setAnchorEl(null)}>
            <Box p={2}>
                <Grid container>
                    <Grid item xs={2}>
                        <Typography variant="caption">From:</Typography>
                    </Grid>
                    <Grid item xs={10}>
                        <Typography variant="caption">
                            {`${email.from.name} <${email.from.emailAddress}>`}
                        </Typography>
                    </Grid>
                </Grid>

                {email?.to && (
                    <Grid container>
                        <Grid item xs={2}>
                            <Typography variant="caption">To:</Typography>
                        </Grid>
                        <Grid item xs={10}>
                            <Typography variant="caption">
                                {email.to
                                    .map((contact) => contact.name + " <" + contact.emailAddress + ">")
                                    .concat(
                                        email?.cc.map(
                                            (contact) => contact.name + " <" + contact.emailAddress + ">"
                                        )
                                    )
                                    .join(", ")
                                    .trim()}
                            </Typography>
                        </Grid>
                    </Grid>
                )}
                {email?.cc && (
                    <Grid container>
                        <Grid item xs={2}>
                            <Typography variant="caption" width={theme.spacing(6)}>
                                Cc:
                            </Typography>
                        </Grid>
                        <Grid item xs={10}>
                            <Typography variant="caption">
                                {email.cc
                                    .map((contact) => contact.name + " <" + contact.emailAddress + ">")
                                    .join(", ")}
                            </Typography>
                        </Grid>
                    </Grid>
                )}
                {email?.reportedTimeCreated && (
                    <Grid container>
                        <Grid item xs={2}>
                            <Typography variant="caption">Date:</Typography>
                        </Grid>
                        <Grid item xs={10}>
                            <Typography variant="caption">{formatDate(email.reportedTimeCreated)}</Typography>
                        </Grid>
                    </Grid>
                )}
                {email?.subject && (
                    <Grid container>
                        <Grid item xs={2}>
                            <Typography variant="caption">Subject:</Typography>
                        </Grid>
                        <Grid item xs={10}>
                            <Typography variant="caption">{email.subject}</Typography>
                        </Grid>
                    </Grid>
                )}
            </Box>
        </Menu>
    );
};

const EmailSummaryExpanded: React.FC<{
    id: EmailId;
    anchorEl: null | HTMLElement;
    setAnchorEl: React.Dispatch<React.SetStateAction<HTMLElement | null>>;
}> = ({ id, anchorEl, setAnchorEl }) => {
    const theme = useTheme();

    const { workspaceId } = useAppSelector((state) => state.ui);
    const {
        data: email,
        error,
        isLoading,
    } = useGetEmailQuery({ id, workspaceId }, { skip: !id || !workspaceId });

    if (isLoading || error || !email)
        return <StatusHelper isLoading={!!isLoading} error={!!error} data={!!email} />;

    return (
        <Box
            display="flex"
            justifyContent={"space-between"}
            width="100%"
            sx={{ fontWeight: email?.isUnread ? "bold" : "normal" }}
        >
            <Grid container spacing={2}>
                <Grid item>
                    <Avatar
                        alt={email?.from?.name}
                        sx={{
                            width: theme.spacing(3),
                            height: theme.spacing(3),
                            bgcolor: stringToColor(email?.from?.name),
                        }}
                    >
                        {initials(email?.from?.name)}
                    </Avatar>
                </Grid>
                <Grid item xs={12} sm container>
                    <Grid item container direction="column" spacing={1}>
                        <Grid item>
                            <Box mr={2} flex={`0 0 ${theme.spacing(20)}`}>
                                <LineClamp lines={1}>
                                    {email.from.name}
                                    {` <${email.from.emailAddress}>`}
                                </LineClamp>
                            </Box>
                        </Grid>
                        <Grid item>
                            <Box>
                                <Typography variant="caption">
                                    To:{" "}
                                    {email?.to
                                        .map((contact) => contact.name + " <" + contact.emailAddress + ">")
                                        .concat(
                                            email?.cc.map(
                                                (contact) => contact.name + " <" + contact.emailAddress + ">"
                                            )
                                        )
                                        .join(", ")}
                                </Typography>

                                <Box display="inline-block" ml={1}>
                                    <IconButton
                                        aria-label="Email Details"
                                        size="small"
                                        sx={{ padding: "0px" }}
                                        onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                                            e.stopPropagation();
                                            setAnchorEl(e.currentTarget);
                                        }}
                                    >
                                        <ExpandMoreIcon
                                            sx={{ width: theme.spacing(2), height: theme.spacing(2) }}
                                        />
                                    </IconButton>
                                    <EmailDetailsMenu
                                        anchorEl={anchorEl}
                                        setAnchorEl={setAnchorEl}
                                        email={email}
                                    />
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item>
                    <Box flexGrow={1} textAlign="right" mr={1} minHeight={"27px"} alignItems="normal">
                        {email?.internalSenderUserDisplayName != null && (
                            <Box display="inline-block" position="relative" mr={1}>
                                <Tooltip title="Sender">
                                    <Chip
                                        color="default"
                                        size="small"
                                        label={email.internalSenderUserDisplayName}
                                        icon={<SupervisedUserCircleIcon />}
                                    />
                                </Tooltip>
                            </Box>
                        )}

                        {email?.deliveredTime != null && (
                            <Box display="inline-block" position="relative" mr={1}>
                                <Tooltip title={formatDate(email.deliveredTime || "")}>
                                    <Chip
                                        color="primary"
                                        size="small"
                                        label="Delivered"
                                        icon={<MoveToInboxIcon />}
                                    />
                                </Tooltip>
                            </Box>
                        )}

                        {email?.firstOpenedTime != null && (
                            <Box display="inline-block" position="relative" mr={1}>
                                <Tooltip title={"First: " + formatDate(email.firstOpenedTime || "")}>
                                    <Chip color="success" size="small" label="Opened" icon={<DraftsIcon />} />
                                </Tooltip>
                            </Box>
                        )}

                        {email?.attachments && email.attachments.length > 0 && (
                            <Box display="inline-block" position="relative" mr={1} top={2}>
                                <AttachFileIcon fontSize="small" />
                            </Box>
                        )}
                        <Typography variant="caption">
                            {formatDate(email?.reportedTimeCreated || "")}
                        </Typography>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
};

const EmailDetailsExpanded: React.FC<{
    id: EmailId;
    isLast: boolean | undefined;
    selectedIndex: ResponseActionButtonsOptionIndex;
    setSelectedIndex: React.Dispatch<React.SetStateAction<ResponseActionButtonsOptionIndex>>;
}> = ({ id, isLast, selectedIndex, setSelectedIndex }) => {
    const theme = useTheme();

    const { workspaceId } = useAppSelector((state) => state.ui);
    const { data: email, error, isLoading } = useGetEmailQuery({ id, workspaceId });

    if (isLoading || error || !email)
        return <StatusHelper isLoading={!!isLoading} error={!!error} data={!!email} />;
    return (
        // outside box is width of viewport minus the left and right padding of child + width of workspace selector
        <Box sx={{ width: `calc(100vw - ${theme.spacing(13)})` }} overflow={"hidden"}>
            <Box ml={theme.spacing(5)} mr={theme.spacing(4)} sx={{ overflowX: "auto" }}>
                <Box>
                    {/* <div dangerouslySetInnerHTML={{ __html: email?.htmlBody || email?.textBody || "" }}></div> */}

                    <SafeHTML html={email?.htmlBody || email?.textBody || ""} />
                </Box>
                {email.htmlBodyHistory && (
                    <Box>
                        <Accordion
                            sx={{ boxShadow: "none" }}
                            disableGutters={true}
                            elevation={0}
                            square
                            TransitionProps={{ timeout: 0 }}
                        >
                            <AccordionSummary
                                expandIcon={
                                    <Tooltip title="Show trimmed content">
                                        <IconButton aria-label="Example" size="small" sx={{ padding: "0px" }}>
                                            <MoreHorizIcon />
                                        </IconButton>
                                    </Tooltip>
                                }
                                aria-controls="panel1-content"
                                id="panel1-header"
                                sx={{
                                    flexDirection: "row-reverse",
                                    transform: "rotate(0deg)",
                                    padding: "0px",
                                }}
                            >
                                <Typography></Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                {/* <div dangerouslySetInnerHTML={{ __html: email?.htmlBodyHistory || "" }} /> */}
                                <SafeHTML html={email?.htmlBodyHistory || ""} />
                            </AccordionDetails>
                        </Accordion>
                    </Box>
                )}

                {email?.attachments && (
                    <AttachmentsList attachments={email.attachments} actionType="download" />
                )}

                {isLast && !selectedIndex && (
                    <ResponseActionButtonsCTA
                        setSelectedIndex={setSelectedIndex}
                        selectedIndex={selectedIndex}
                    />
                )}
                <Box mt={2}>
                    <Compose
                        setSelectedIndex={setSelectedIndex}
                        selectedIndex={selectedIndex}
                        expandedEmail={email}
                    />
                </Box>
            </Box>
        </Box>
    );
};
