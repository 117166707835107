import { Box, Typography } from "@mui/material";
import { useAppSelector, useAppDispatch } from "../redux/hooks";
import { useTheme } from "@mui/material/styles";
import {
    Flag as OpportunityIcon,
    OpenInFull as OpenInFullIcon,
    Minimize as MinimizeIcon,
} from "@mui/icons-material";
import { DRAWER_WIDTH_UNITS } from "../config/ui";
import { toggleDrawer } from "../redux/uiSlice";
import { OpportunityComponent } from "./OpportunityComponent";
import { useNavigate } from "react-router-dom";

export const OpportunityDrawer: React.FC<{}> = () => {
    const navigate = useNavigate();
    const theme = useTheme();
    const dispatch = useAppDispatch();
    const drawerOpen = useAppSelector((state) => state.ui.drawerOpen);
    const opportunityId = useAppSelector((state) => state.ui.currentOpportunityId);
    return opportunityId ? (
        <>
            <Box
                position="absolute"
                top={0}
                right={0}
                bgcolor={theme.palette.background.paper}
                width={theme.spacing(2)}
                height={theme.spacing(12)}
                borderRadius={theme.spacing(0.25)}
                display={drawerOpen ? "none" : "block"}
                boxShadow={`-2px 2px 5px ${theme.palette.grey[300]}`}
                // change cursor to pointer when this Box is hovered
                sx={{ "&:hover": { cursor: "pointer", color: theme.palette.secondary.main } }}
                onClick={() => dispatch(toggleDrawer())}
            >
                <Box bgcolor={theme.palette.background.paper}>
                    <Box mb={theme.spacing(0.1)} sx={{ rotate: "90deg" }}>
                        <OpportunityIcon sx={{ fontSize: theme.spacing(1.5) }} />
                    </Box>
                    <Box sx={{ rotate: "90deg" }} position="relative" left="0px">
                        <Typography variant="caption">opportunity</Typography>
                    </Box>
                </Box>
            </Box>

            <Box
                position="absolute"
                top={0}
                right={0}
                bgcolor={theme.palette.background.paper}
                width={theme.spacing(DRAWER_WIDTH_UNITS)}
                borderRadius={theme.spacing(0.25)}
                display={drawerOpen ? "block" : "none"}
                boxShadow={`-2px 2px 5px ${theme.palette.grey[300]}`}
            >
                <Box
                    position="absolute"
                    top={0}
                    left={0}
                    bgcolor={theme.palette.background.paper}
                    borderBottom={`1px solid ${theme.palette.grey[500]}`}
                    width="100%"
                >
                    <Box display="inline-block" ml={1} position="relative" top="2px">
                        <OpportunityIcon sx={{ fontSize: theme.spacing(1.5) }} />
                    </Box>
                    <Box display="inline-block" ml={0.5}>
                        <Typography variant="caption">opportunity</Typography>
                    </Box>

                    <Box position="absolute" top={0} right={0} mr={1}>
                        <Box
                            ml={1}
                            display="inline-block"
                            sx={{ "&:hover": { cursor: "pointer", color: theme.palette.secondary.main } }}
                            onClick={() => dispatch(toggleDrawer())}
                        >
                            <MinimizeIcon sx={{ fontSize: theme.spacing(1.5) }} />
                        </Box>

                        <Box
                            ml={1}
                            display="inline-block"
                            sx={{ "&:hover": { cursor: "pointer", color: theme.palette.secondary.main } }}
                            onClick={() => navigate(`/opportunity/${opportunityId}`)}
                        >
                            <OpenInFullIcon sx={{ fontSize: theme.spacing(1.5) }} />
                        </Box>
                    </Box>
                </Box>

                <Box mt={3}>
                    <OpportunityComponent variant="card" />
                </Box>
            </Box>
        </>
    ) : null;
};
