import { FileUpload, FileUploadProps } from "./FileUpload";
import { Snackbar, Alert, Modal, Box, LinearProgress, Typography } from "@mui/material";
import { AttachFile as AttachEmailIcon } from "@mui/icons-material";
import { useAppSelector, useAppDispatch } from "../redux/hooks";
import { s3FileUpload } from "../utils/s3fileupload";
import React, { useContext } from "react";
import { AuthContext, IAuthContext } from "../react-oauth2-code-pkce";
import { Attachment, ContentTypeValidSend, ContentTypeValidSendEnum } from "../types/EmailTypes";
import { addDraftEmailAttachment } from "../redux/uiSlice";
import { useTheme } from "@mui/material/styles";

interface EmailAttachmentModalProps {}

export const EmailAttachmentModal: React.FC<EmailAttachmentModalProps> = () => {
    const theme = useTheme();
    const [loading, setLoading] = React.useState<boolean>(false);
    const [error, setError] = React.useState<string>("");
    const [open, setOpen] = React.useState(false);
    const { workspaceId } = useAppSelector((state) => state.ui);
    const { token }: IAuthContext = useContext(AuthContext);
    const dispatch = useAppDispatch();

    const processFileUpload = async (file: File, token: string, workspaceId: string) => {
        try {
            if (!workspaceId || !token) throw new Error("Missing workspaceId or token");

            if (!ContentTypeValidSend.includes(file.type as ContentTypeValidSendEnum))
                throw new Error("Invalid file type");

            setLoading(true);
            const signResponse = await s3FileUpload(file, token, workspaceId);
            setLoading(false);

            const attachment: Attachment = {
                name: file.name,
                url: signResponse.default,
                contentType: file.type,
                type: "attachment",
            };
            dispatch(addDraftEmailAttachment(attachment));
            setOpen(false);
        } catch (e: any) {
            setError(e.message);
            console.error(e);
        }
    };

    const fileUploadProp: FileUploadProps = {
        accept: "*/*",
        onChange: async (event: React.ChangeEvent<HTMLInputElement>) => {
            if (event.target.files !== null && event.target?.files?.length > 0) {
                console.log(`Saving ${event.target.value}`);

                await processFileUpload(event.target.files[0], token, workspaceId);
            }
        },
        onDrop: async (event: React.DragEvent<HTMLElement>) => {
            console.log(`Drop ${event.dataTransfer.files[0].name}`);
            await processFileUpload(event.dataTransfer.files[0], token, workspaceId);
        },
    };

    return (
        <>
            <Snackbar open={!!error} autoHideDuration={6000} onClose={() => setError("")}>
                <Alert severity="error">{error}</Alert>
            </Snackbar>
            <Box
                onClick={() => setOpen(true)}
                px={1}
                sx={{
                    height: "100%",
                    borderColor: "grey.400",
                    borderWidth: 1,
                    borderRadius: 1,
                    borderStyle: "solid",
                    cursor: "pointer",
                    backgroundColor: "white",
                    "&:hover": {
                        backgroundColor: theme.palette.grey[100],
                        borderColor: theme.palette.grey[800],
                    },
                }}
                display="flex"
                alignItems="center"
            >
                <AttachEmailIcon sx={{ color: "grey" }} />
            </Box>
            <Modal open={open} onClose={() => setOpen(false)}>
                <Box
                    sx={{
                        position: "absolute" as "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        bgcolor: "background.paper",
                        p: 4,
                        borderRadius: "5px",
                    }}
                >
                    {loading ? (
                        <Box width={theme.spacing(35)} height={theme.spacing(12)}>
                            <Box pt={2}>
                                <Typography variant="caption">Uploading</Typography>
                            </Box>
                            <Box mt={2}>
                                <LinearProgress />
                            </Box>
                        </Box>
                    ) : (
                        <FileUpload
                            {...fileUploadProp}
                            width={theme.spacing(35)}
                            height={theme.spacing(12)}
                        />
                    )}
                </Box>
            </Modal>
        </>
    );
};
