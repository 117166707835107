import { Box, Button, CircularProgress, Snackbar, Alert, IconButton } from "@mui/material";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import Editor from "ckeditor5-custom-build";
import React, { useRef, useState, useContext, useEffect } from "react";
import { ResponseActionButtonsOptionIndex } from "./ResponseActionButtons";
import { useAppDispatch } from "../redux/hooks";
import { updateDraftEmail, addDraftEmailAttachment, updateSubject } from "../redux/uiSlice";
import { COMPOSE_EDITOR_MAX_HEIGHT, COMPOSE_EDITOR_MIN_HEIGHT } from "../config/ui";
import { PersonInput } from "./PersonInput";
import { AuthContext, IAuthContext } from "../react-oauth2-code-pkce";
import { MADEON_API_SERVER } from "../config/servers";
import { useAppSelector } from "../redux/hooks";
// import { ckeditorHTMLWrapper } from "../utils/ckeditorHTMLWrapper";
import { Attachment } from "../types/EmailTypes";
import { usePostEmailMutation } from "../api/emailApi";
import { EmailPostRequestBody, Email } from "../types/EmailTypes";
import { EmailAttachmentModal } from "./EmailAttachmentModal";
import { decodeHtmlEntities } from "../utils/decodeHtmlEntities";
import { v4 as uuidv4 } from "uuid";
import { replaceInHaystack } from "../utils/replaceInHaystack";
import { Send as SendIcon, DeleteSharp as DeleteIcon } from "@mui/icons-material";
import { getRtkErrorMessage } from "../utils/getRtkErrorMessage";
import { AttachmentsList } from "./AttachmentsList";
import TextField from "@mui/material/TextField";

interface ComposeProps {
    selectedIndex: ResponseActionButtonsOptionIndex;
    setSelectedIndex: React.Dispatch<React.SetStateAction<ResponseActionButtonsOptionIndex>>;
    expandedEmail?: Email;
    postSendCallback?: () => void;
}

export const Compose: React.FC<ComposeProps> = ({
    selectedIndex,
    setSelectedIndex,
    expandedEmail,
    postSendCallback,
}) => {
    const dispatch = useAppDispatch();
    const editorRef = useRef<Editor>();
    const [touched, setTouched] = useState<boolean>(false);
    const { token }: IAuthContext = useContext(AuthContext);
    const workspaceId = useAppSelector((state) => state.ui.workspaceId);
    const draftEmail = useAppSelector((state) => state.ui?.draftEmail);
    const [postEmail, { error: sendError, isLoading }] = usePostEmailMutation();
    const [error, setError] = useState<string>("");

    const [subjectTextInput, setSubjectTextInput] = useState("");
    const handleSubjectTextInputChange = (event: { target: { value: React.SetStateAction<string> } }) => {
        dispatch(updateSubject(String(event.target.value)));
        setSubjectTextInput(event.target.value);
        console.log("Subject value: " + draftEmail?.subject);
    };

    // set error message if sendEmail mutation fails
    useEffect(() => sendError && setError(getRtkErrorMessage(sendError)), [sendError]);

    // event listener fired by S3UploadAdapter when an inline image is uploaded
    useEffect(() => {
        const handleInlineImageUploaded = (e: any) => {
            const attachment = e?.detail?.attachment as Attachment;
            if (attachment) dispatch(addDraftEmailAttachment(attachment));
        };
        document.addEventListener("inlineImageUploaded", handleInlineImageUploaded);
        return () => document.removeEventListener("inlineImageUploaded", handleInlineImageUploaded);
    }, [dispatch]);

    const handleEmailSend = async () => {
        if (draftEmail) {
            const { htmlBody, from, to, cc, bcc, subject, attachments } = draftEmail;

            // filter out inline attachments that are not in the html body
            const inlineAttachments = attachments
                .filter((attachment) => {
                    return (
                        attachment.type === "inline" && decodeHtmlEntities(htmlBody).includes(attachment.url)
                    );
                })
                .map(({ name, url, contentType }) => ({ name, url, contentType, contentId: uuidv4() }));

            // replace inline attachment urls with cid:contentId
            const cidHtmlBody = replaceInHaystack(
                decodeHtmlEntities(htmlBody),
                inlineAttachments.map(({ url, contentId }) => ({ find: url, replace: `cid:${contentId}` }))
            );

            // construct email object to post
            var email: EmailPostRequestBody = {
                from: from.emailAddress,
                to: to.map(({ name, emailAddress }) => ({ name, emailAddress })),
                cc: cc.map(({ name, emailAddress }) => ({ name, emailAddress })),
                bcc: bcc.map(({ name, emailAddress }) => ({ name, emailAddress })),
                subject,
                htmlBody: cidHtmlBody,
                attachments: [
                    ...attachments
                        .filter((attachment) => attachment.type === "attachment")
                        .map(({ name, url, contentType }) => ({ name, url, contentType })),
                    ...inlineAttachments,
                ],
            };

            // If there is an email we're replying to, include the inReplyToMessageId
            if (expandedEmail != null) {
                email.inReplyToEmailMessageId = expandedEmail.id;
            }

            // post email.  if successful, reset selectedIndex to 0 (no display)
            try {
                await postEmail({ email, workspaceId }).unwrap();
                setSelectedIndex(0);
            } catch (err) {
                console.error(err);
            }
        }
    };

    // handle updating draft email in redux store
    // TODO: debounce this
    // TODO: removed ckeditorHTMLWrapper
    // ckeditorHTMLWrapper(editorRef.current?.getData() || "")
    // FIX: This
    // subject: expandedEmail != null ? expandedEmail.subject : draftEmail?.subject != null ? draftEmail.subject : ""
    const handleEmailUpdate = () =>
        dispatch(updateDraftEmail({ htmlBody: editorRef.current?.getData() || "" }));

    return selectedIndex ? (
        <>
            <Snackbar open={!!error} autoHideDuration={6000} onClose={() => setError("")}>
                <Alert severity="error">{error}</Alert>
            </Snackbar>

            <Box display="flex" alignItems="stretch">
                <Box mb={2} sx={{ flexGrow: 1 }}>
                    <PersonInput selectedIndex={selectedIndex} setSelectedIndex={setSelectedIndex} />
                </Box>
                <Box ml={1} mb={2}>
                    <EmailAttachmentModal />
                </Box>
            </Box>

            {/* Subject */}
            {expandedEmail == null && (
                <Box display="flex" alignItems="flex-start">
                    <Box mb={2} sx={{ flexGrow: 1 }}>
                        <TextField
                            required
                            margin="dense"
                            id="name"
                            name="subject"
                            label="Subject"
                            type="email"
                            fullWidth
                            variant="standard"
                            value={subjectTextInput}
                            onChange={handleSubjectTextInputChange}
                        />
                    </Box>
                </Box>
            )}

            {/* Text Editor */}
            <CKEditor
                editor={Editor}
                config={{
                    minHeight: COMPOSE_EDITOR_MIN_HEIGHT,
                    maxHeight: COMPOSE_EDITOR_MAX_HEIGHT,
                    token,
                    apiServer: MADEON_API_SERVER,
                    workspaceId,
                }}
                onReady={(editor) => (editorRef.current = editor)}
                onChange={(event, editor) => {
                    if (!touched) setTouched(true);
                    handleEmailUpdate();
                }}
            />
            {/* Send Button */}
            <Box display="flex" justifyContent={"space-between"} width="100%">
                <Box mt={2}>
                    <Button
                        variant="contained"
                        color="primary"
                        disabled={!touched || isLoading}
                        startIcon={isLoading ? <CircularProgress size={20} /> : <SendIcon />}
                        onClick={() => {
                            handleEmailUpdate();
                            handleEmailSend();
                            postSendCallback && postSendCallback();
                        }}
                    >
                        Send
                    </Button>
                </Box>
                <Box mt={2} textAlign="right">
                    <IconButton
                        aria-label="Example"
                        size="small"
                        onClick={() => {
                            setSelectedIndex(0);
                            postSendCallback && postSendCallback();
                        }}
                    >
                        <DeleteIcon />
                    </IconButton>
                </Box>
            </Box>

            {/* Attachments */}
            {draftEmail?.attachments && (
                <AttachmentsList
                    actionType="delete"
                    attachments={draftEmail.attachments.filter(
                        (attachment) => attachment.type === "attachment"
                    )}
                />
            )}
        </>
    ) : null;
};
