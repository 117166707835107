import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { MADEON_API_SERVER } from "../config/servers";
import { Whatsapp } from "../types/WhatsappTypes";
import { MessageId } from "../types/SharedTypes";
import { WorkspaceId } from "../types/WorkspaceTypes";
import { getBearerTokenHeader } from "../utils/getToken";
const contentTypeJsonHeader = { "Content-Type": "application/json" };

interface GetWhatsappMessagePayload {
    messageId: MessageId;
    workspaceId: WorkspaceId;
}

interface GetWhatsappMessagesPayload {
    messageIds: MessageId[];
    workspaceId: WorkspaceId;
}

export const whatsappApi = createApi({
    reducerPath: "whatsappApi",
    baseQuery: fetchBaseQuery({ baseUrl: MADEON_API_SERVER }),
    tagTypes: ["Messages"],
    endpoints: (builder) => ({
        getWhatsappMessage: builder.query<Whatsapp, GetWhatsappMessagePayload>({
            query: ({ messageId, workspaceId }) =>
                messageId &&
                workspaceId && {
                    url: `/whatsapp/${messageId}`,
                    headers: {
                        ...contentTypeJsonHeader,
                        ...getBearerTokenHeader(),
                        "x-workspace-id": workspaceId,
                    },
                },
            providesTags: (result) =>
                result
                    ? [
                          { type: "Messages", id: result.MessageId },
                          { type: "Messages", id: "LIST" },
                      ]
                    : [{ type: "Messages", id: "LIST" }],
        }),
        getWhatsappMessages: builder.query<Whatsapp[], GetWhatsappMessagesPayload>({
            query: ({ messageIds: body, workspaceId }) =>
                body &&
                workspaceId && {
                    url: `/whatsapp?messageIds=${encodeURIComponent(JSON.stringify(body))}`,
                    headers: {
                        ...contentTypeJsonHeader,
                        ...getBearerTokenHeader(),
                        "x-workspace-id": workspaceId,
                    },
                },
            providesTags: (result) =>
                result
                    ? [
                          ...result.map((whatsapp) => ({
                              type: "Messages" as const,
                              id: whatsapp.MessageId,
                          })),
                          { type: "Messages", id: "LIST" },
                      ]
                    : [{ type: "Messages", id: "LIST" }],
        }),
    }),
});

export const { useGetWhatsappMessageQuery, useGetWhatsappMessagesQuery } = whatsappApi;
