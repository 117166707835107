import { config } from "../config/oauth";
export const getCookie = (name: string): string | null => {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop()?.split(";").shift() ?? null;
    return null;
};

export const getToken = (): string | null => {
    const token = getCookie(`${config.storageKeyPrefix}token`);

    try {
        return token ? JSON.parse(token) : null;
    } catch (error: any) {
        console.warn(
            `Failed to parse stored value for 'token'.\nContinuing with default value.\nError: ${error.message}`
        );
    }
    return null;
};

export const getBearerTokenHeader = (): { Authorization: string } => ({
    Authorization: `Bearer ${getToken()}`,
});
