import { ReactNode, useContext } from "react";
import { Box } from "@mui/material";
import { Workspaces } from "./Workspaces";
import { Navbar } from "./Navbar";

import { AuthContext, IAuthContext } from "../react-oauth2-code-pkce";
import { UnauthenticatedLanding } from "./LoginComponents";
import { useTheme } from "@mui/material/styles";
import { NO_AUTH } from "../config/constants";

interface DefaultLayoutProps {
    noWorkspaces?: boolean;
    children: ReactNode;
    pagination?: boolean;
}

export const DefaultLayout: React.FC<DefaultLayoutProps> = ({ children, noWorkspaces, pagination }) => {
    const { token }: IAuthContext = useContext(AuthContext);
    const theme = useTheme();

    return (
        <>
            <Navbar />
            <Box display="flex">
                {token || NO_AUTH ? (
                    <>
                        {!noWorkspaces && (
                            <Box bgcolor={theme.palette.grey[200]}>
                                <Workspaces />
                            </Box>
                        )}

                        <Box flexGrow={1}>
                            <Box ml={0.5}>{children}</Box>
                        </Box>
                    </>
                ) : (
                    <UnauthenticatedLanding />
                )}
            </Box>
        </>
    );
};

DefaultLayout.defaultProps = {
    noWorkspaces: false,
    pagination: false,
};
