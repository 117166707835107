import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { WorkspacePriorities, WorkspaceId, WorkspaceConfig } from "../types/WorkspaceTypes";
import { ThreadFilter, ThreadId } from "../types/ThreadTypes";
import { ALL_PRIORITIES_ID } from "../config/constants";
import { PriorityId } from "../types/PriorityTypes";
import { EmailContact, Email, blankDraftEmail, EmailPrimary, Attachment } from "../types/EmailTypes";
import { MessageId } from "../types/SharedTypes";
import { ResponseActionButtonsOptionIndex } from "../components/ResponseActionButtons";
import { OpportunityId } from "../types/OpportunityTypes";

interface archiveThreadCheckboxStatus {
    threadId: ThreadId;
    checked: boolean;
}

interface UiState {
    workspaceTab: number;
    workspaceId: WorkspaceId;
    drawerOpen: boolean;
    workspacePrioritiesArray: WorkspacePriorities[];
    selectedPriorityId: PriorityId;
    currentThreadFilter: ThreadFilter;
    currentMessageId: MessageId;
    currentOpportunityId: OpportunityId;
    currentEmail?: EmailPrimary;
    draftEmail?: Email;
    archiveThreadCheckboxes: archiveThreadCheckboxStatus[];
}

const uiSliceInitialState: UiState = {
    workspaceTab: 0,
    workspaceId: "",
    drawerOpen: false,
    workspacePrioritiesArray: [],
    selectedPriorityId: ALL_PRIORITIES_ID,
    currentThreadFilter: { workspaceId: "" },
    currentMessageId: "",
    currentOpportunityId: "",
    archiveThreadCheckboxes: [],
};

export const uiSlice = createSlice({
    name: "ui",
    initialState: uiSliceInitialState,
    reducers: {
        setWorkspaceTab: (state, action: PayloadAction<number>) => {
            state.workspaceTab = action.payload;
        },
        setWorkspaceId: (state, action: PayloadAction<string>) => {
            state.workspaceId = action.payload;
        },
        setOpportunityId: (state, action: PayloadAction<OpportunityId>) => {
            state.currentOpportunityId = action.payload;
        },
        setDrawerOpen: (state, action: PayloadAction<boolean>) => {
            state.drawerOpen = action.payload;
        },
        toggleDrawer: (state) => {
            state.drawerOpen = !state.drawerOpen;
        },
        setSelectedPriorityId: (state, action: PayloadAction<PriorityId>) => {
            state.selectedPriorityId = action.payload;
        },
        setCurrentThreadFilter: (state, action: PayloadAction<ThreadFilter>) => {
            state.currentThreadFilter = action.payload;
        },
        setCurrentThreadFilterArr: (state, action: PayloadAction<Partial<ThreadFilter[]>>) => {
            // Assuming ThreadFilter is an array of objects.  spread each object in the array into the currentThreadFilter
            state.currentThreadFilter = Object.assign({}, ...action.payload);
        },
        setCurrentMessageId: (state, action: PayloadAction<MessageId>) => {
            state.currentMessageId = action.payload;
        },
        setCurrentEmail: (state, action: PayloadAction<EmailPrimary>) => {
            state.currentEmail = action.payload;
        },
        updateDraftEmail: (state, action: PayloadAction<Partial<Email>>) => {
            state.draftEmail = { ...blankDraftEmail, ...state.draftEmail, ...action.payload };
        },
        addDraftEmailAttachment: (state, action: PayloadAction<Attachment>) => {
            state.draftEmail = {
                ...blankDraftEmail,
                ...state.draftEmail,
                attachments: [
                    ...(state?.draftEmail && state?.draftEmail?.attachments.length > 0
                        ? state.draftEmail.attachments
                        : []),
                    action.payload,
                ],
            };
        },
        removeDraftEmailAttachment: (state, action: PayloadAction<Attachment>) => {
            state.draftEmail = {
                ...blankDraftEmail,
                ...state.draftEmail,
                attachments:
                    state?.draftEmail?.attachments.filter(
                        (attachment) => attachment.url !== action.payload.url
                    ) || [],
            };
        },

        resetDraftEmail: (state) => {
            const FromFullTemp: EmailContact = {
                emailAddress: "noreply@shinmedical.com",
                name: "Shin Medical",
                people: [],
            };

            state.draftEmail = {
                ...blankDraftEmail,
                from: FromFullTemp,
                to: [],
                cc: [],
                bcc: [],
            };
        },

        updateSubject: (state, action: PayloadAction<String>) => {
            if (state.draftEmail != null) {
                state.draftEmail.subject = action.payload.valueOf();
                console.log("UpdateSubject: " + state.draftEmail.subject);
            }

            return;
        },

        updateDraftRecipients: (
            state,
            action: PayloadAction<{
                index: ResponseActionButtonsOptionIndex;
                workspaceConfig: WorkspaceConfig | undefined;
            }>
        ) => {
            const FromFullTemp: EmailContact = {
                emailAddress: "noreply@shinmedical.com",
                name: "Shin Medical",
                people: [],
            };

            if (state.currentEmail === undefined) {
                state.draftEmail = {
                    ...blankDraftEmail,
                    ...state.draftEmail,
                    from: FromFullTemp,
                    to: [],
                    cc: [],
                    bcc: [],
                };
                return;
            }

            switch (action.payload.index) {
                case 1: // reply
                    state.draftEmail = {
                        ...blankDraftEmail,
                        ...state.draftEmail,
                        from: FromFullTemp,
                        to:
                            // if the FROM of the current email is the same as the workspaceConfig sendAsEmailAddress,
                            // and if the (first) TO of the current email is not the same as the workspaceConfig sendAsEmailAddress
                            //then set the TO to the FROM of the current email
                            action.payload.workspaceConfig?.sendAsEmailAddress ===
                                state.currentEmail.from.emailAddress &&
                            action.payload.workspaceConfig?.sendAsEmailAddress !==
                                state.currentEmail.to[0].emailAddress
                                ? [state.currentEmail.to[0]]
                                : [state.currentEmail.from],
                        cc: [],
                        bcc: [],
                    };
                    break;
                case 2: // reply all
                    state.draftEmail = {
                        ...blankDraftEmail,
                        ...state.draftEmail,
                        from: FromFullTemp,
                        to: [state.currentEmail.from, ...state.currentEmail.to],
                        cc: state.currentEmail.cc,
                        bcc: [],
                    };

                    break;
                case 3: // forward
                    state.draftEmail = {
                        ...blankDraftEmail,
                        ...state.draftEmail,
                        from: FromFullTemp,
                        to: [],
                        cc: [],
                        bcc: [],
                    };
                    break;
                default:
                    break;
            }
        },
        setDraftEmailToRecipients: (state, action: PayloadAction<EmailContact[]>) => {
            if (state.draftEmail === undefined) return;

            state.draftEmail = {
                ...blankDraftEmail,
                to: [...action.payload],
                cc: [],
            };
        },
        setArchiveThreadCheckboxes: (state, action: PayloadAction<archiveThreadCheckboxStatus[]>) => {
            state.archiveThreadCheckboxes = action.payload;
        },
        setArchiveThreadCheckbox: (state, action: PayloadAction<archiveThreadCheckboxStatus>) => {
            state.archiveThreadCheckboxes = state.archiveThreadCheckboxes.map((checkbox) =>
                checkbox.threadId === action.payload.threadId ? action.payload : checkbox
            );
        },
        setAllArchiveThreadCheckboxesToTrue: (state) => {
            state.archiveThreadCheckboxes = state.archiveThreadCheckboxes.map((checkbox) => ({
                threadId: checkbox.threadId,
                checked: true,
            }));
        },
        setAllArchiveThreadCheckboxesToFalse: (state) => {
            state.archiveThreadCheckboxes = state.archiveThreadCheckboxes.map((checkbox) => ({
                threadId: checkbox.threadId,
                checked: false,
            }));
        },
    },
});

export const {
    setCurrentThreadFilter,
    setCurrentThreadFilterArr,
    setSelectedPriorityId,
    setWorkspaceId,
    setDrawerOpen,
    toggleDrawer,
    setWorkspaceTab,
    setCurrentMessageId,
    setCurrentEmail,
    updateDraftEmail,
    updateSubject,
    resetDraftEmail,
    updateDraftRecipients,
    setDraftEmailToRecipients,
    setOpportunityId,
    addDraftEmailAttachment,
    removeDraftEmailAttachment,
    setArchiveThreadCheckboxes,
    setAllArchiveThreadCheckboxesToTrue,
    setAllArchiveThreadCheckboxesToFalse,
    setArchiveThreadCheckbox,
} = uiSlice.actions;
