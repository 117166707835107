import React, { useState, useEffect } from "react";
import {
    Box,
    TextField,
    Container,
    Avatar,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    SelectChangeEvent,
    Paper,
    CardHeader,
    CardContent,
    List,
    ListItem,
    ListItemText,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useGetPersonQuery } from "../api/personApi";
import { useParams } from "react-router-dom";
import { useAppSelector } from "../redux/hooks";
import { StatusHelper } from "./StatusComponents";
import { PersonContacts, blankPersonContacts, KoreanVisaEnumValues } from "../types/PersonTypes";
import { DateTime } from "luxon";
// import { deepDiff } from "../utils/deepDiff";
import { stringToColor } from "../utils/stringToColor";
import { initials } from "../utils/initials";

export const PersonComponent: React.FC<{}> = () => {
    const theme = useTheme();
    const { personId } = useParams();
    const { workspaceId } = useAppSelector((state) => state.ui);
    const { data, isLoading, error } = useGetPersonQuery(
        { workspaceId, personId: personId || "" },
        { skip: !personId || !workspaceId }
    );
    const [isEditMode] = useState(false);
    const [formData, setFormData] = useState<PersonContacts>(data || blankPersonContacts);

    // const [patchPerson] = usePatchPersonMutation();

    useEffect(() => {
        if (data) setFormData(data);
    }, [data]);

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;

        setFormData({ ...formData, [name]: value });
    };

    const handleSelectChange = (event: SelectChangeEvent<any>) => {
        const { name, value } = event.target;
        if (name) {
            setFormData((prev) => ({ ...prev, [name]: value as string }));
        }
    };

    /*
    const handleSubmit = () => {
        const diff: Partial<Person> | undefined | null =
            data && formData && (deepDiff(data, formData) as Partial<Person>);

        if (data && diff && Object.keys(diff).length > 0) {
            const personPatch: Partial<Person> = {
                ...diff,
                id: data.id,
                fullName: data.fullName,

                ...(diff?.birthDate
                    ? {
                          DateOfBirth:
                              diff?.birthDate && (DateTime.fromISO(diff.birthDate).toISO() as string),
                      }
                    : {}),
            };
            patchPerson({ personPatch, workspaceId });
        }

        setEditMode(false);
    };*/

    if (isLoading || error || !formData)
        return <StatusHelper isLoading={!!isLoading} error={!!error} data={!!formData} />;

    return (
        <Box mt={2}>
            <Container maxWidth="lg">
                <Paper elevation={3}>
                    <CardHeader
                        avatar={
                            <Avatar
                                sx={{ bgcolor: stringToColor(formData.fullName) }}
                                src={formData.avatarUrl}
                            >
                                {initials(formData.fullName)}
                            </Avatar>
                        }
                        title={formData.fullName}
                        // action={
                        //     <Button
                        //         variant="contained"
                        //         color="primary"
                        //         onClick={isEditMode ? handleSubmit : () => setEditMode(true)}
                        //         startIcon={
                        //             isUpdating ? (
                        //                 <CircularProgress size={10} />
                        //             ) : isEditMode ? (
                        //                 <SaveIcon />
                        //             ) : (
                        //                 <EditIcon />
                        //             )
                        //         }
                        //         disabled={isUpdating}
                        //     >
                        //         {isEditMode ? "Save" : "Edit"}
                        //     </Button>
                        // }
                    />
                    <CardContent>
                        <List>
                            <ListItem>
                                {isEditMode ? (
                                    <TextField
                                        label="Nationality"
                                        name="nationality"
                                        value={formData?.nationality || ""}
                                        fullWidth
                                        onChange={handleInputChange}
                                    />
                                ) : (
                                    <ListItemText primary="Nationality" secondary={formData?.nationality} />
                                )}
                            </ListItem>
                            <ListItem>
                                {isEditMode ? (
                                    <TextField
                                        label="Date of Birth"
                                        name="birthDate"
                                        type="date"
                                        value={
                                            formData?.birthDate &&
                                            DateTime.fromISO(formData.birthDate).toISODate()
                                        }
                                        fullWidth
                                        InputLabelProps={{ shrink: true }}
                                        onChange={handleInputChange}
                                    />
                                ) : (
                                    <ListItemText
                                        primary="Date of Birth"
                                        secondary={
                                            formData?.birthDate &&
                                            DateTime.fromISO(formData.birthDate).toLocaleString(
                                                DateTime.DATE_MED
                                            )
                                        }
                                    />
                                )}
                            </ListItem>
                            <ListItem>
                                {isEditMode ? (
                                    <FormControl fullWidth>
                                        <InputLabel
                                            sx={{
                                                bgcolor: "white",
                                                paddingLeft: theme.spacing(1),
                                                paddingRight: theme.spacing(1),
                                            }}
                                        >
                                            Sex
                                        </InputLabel>
                                        <Select
                                            name="sex"
                                            value={formData?.sex || ""}
                                            onChange={handleSelectChange}
                                        >
                                            <MenuItem value="MALE">Male</MenuItem>
                                            <MenuItem value="FEMALE">Female</MenuItem>
                                        </Select>
                                    </FormControl>
                                ) : (
                                    <ListItemText primary="Sex" secondary={formData?.sex} />
                                )}
                            </ListItem>
                            <ListItem>
                                {isEditMode ? (
                                    <FormControl fullWidth>
                                        <InputLabel
                                            sx={{
                                                bgcolor: "white",
                                                paddingLeft: theme.spacing(1),
                                                paddingRight: theme.spacing(1),
                                            }}
                                        >
                                            Korean Visa
                                        </InputLabel>
                                        <Select
                                            name="koreanVisa"
                                            value={formData?.koreanVisa || ""}
                                            onChange={handleSelectChange}
                                        >
                                            {KoreanVisaEnumValues.map((visa) => (
                                                <MenuItem key={visa} value={visa}>
                                                    {visa}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                ) : (
                                    <ListItemText primary="Korean Visa" secondary={formData?.koreanVisa} />
                                )}
                            </ListItem>

                            <ListItem>
                                <ListItemText
                                    primary="Emails"
                                    secondary={formData.emailAddresses.map((email) => email).join(", ")}
                                />
                            </ListItem>
                        </List>
                    </CardContent>
                </Paper>
            </Container>
        </Box>
    );
};
