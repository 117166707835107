import { useState, useContext } from "react";
import {
    Menu,
    MenuItem,
    AppBar,
    Box,
    Toolbar,
    Typography,
    IconButton,
    ListItemIcon,
    ListItemText,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import {
    Email as EmailIcon,
    WhatsApp as WhatsAppIcon,
    ContactPhone as CustomerIcon,
    Flag as OpportunityIcon,
    Settings,
    Menu as MenuIcon,
    CalendarMonth as CalendarIcon,
} from "@mui/icons-material";
import { useTheme } from "@mui/material/styles";
import { NO_AUTH } from "../config/constants";
import { UserNav } from "./UserNav";
import { AuthContext, IAuthContext } from "../react-oauth2-code-pkce";
import { GlobalSearchInput } from "./GlobalSearchInput";
import useMediaQuery from "@mui/material/useMediaQuery";

export const Navbar = () => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const navigate = useNavigate();
    const theme = useTheme();
    const { token }: IAuthContext = useContext(AuthContext);
    const isMobile = useMediaQuery(theme.breakpoints.down("md"));
    return (
        <>
            <Box sx={{ flexGrow: 1 }}>
                <AppBar position="static" sx={{ boxShadow: 0 }}>
                    <Toolbar>
                        {(token || NO_AUTH) && (
                            <IconButton
                                size="large"
                                edge="start"
                                color="inherit"
                                aria-label="menu"
                                onClick={(e: React.MouseEvent<HTMLButtonElement>) =>
                                    setAnchorEl(e.currentTarget)
                                }
                            >
                                <MenuIcon />
                            </IconButton>
                        )}

                        <Box
                            onClick={() => navigate("/")}
                            sx={{ "&:hover": { cursor: "pointer" }, display: "flex" }}
                        >
                            <Box
                                component="img"
                                sx={{
                                    height: 50,
                                    width: 50,
                                    maxHeight: { xs: 50, md: 50 },
                                    maxWidth: { xs: 50, md: 50 },
                                }}
                                alt="logo"
                                src="/maedeon-logo-white-transparent.png"
                                alignItems="center"
                            />
                            <Typography
                                variant="h6"
                                component="div"
                                sx={{ lineHeight: "3rem", marginLeft: "0.5rem" }}
                            >
                                {!isMobile && "Maedeon Agent"}
                            </Typography>
                        </Box>
                        <Box flexGrow={1} p={1} mx={3} display="flex">
                            {token && <GlobalSearchInput />}
                        </Box>
                        <Box
                            flex={`0 0 ${theme.spacing(5)}`}
                            display="flex"
                            alignItems="center"
                            justifyContent="flex-end"
                        >
                            {token && <Settings sx={{ fontSize: theme.spacing(4) }} />}

                            <Box ml={1}>
                                <UserNav />
                            </Box>
                        </Box>
                    </Toolbar>
                </AppBar>
            </Box>
            <Menu open={Boolean(anchorEl)} onClose={() => setAnchorEl(null)} anchorEl={anchorEl}>
                <MenuItem onClick={() => navigate("/opportunity")}>
                    <ListItemIcon>
                        <OpportunityIcon />
                    </ListItemIcon>
                    <ListItemText>Opportunities</ListItemText>
                </MenuItem>
                <MenuItem onClick={() => navigate("/customer")}>
                    <ListItemIcon>
                        <CustomerIcon />
                    </ListItemIcon>
                    <ListItemText>Customers</ListItemText>
                </MenuItem>
                <MenuItem onClick={() => navigate("/threads?isAll")}>
                    <ListItemIcon>
                        <EmailIcon />
                    </ListItemIcon>
                    <ListItemText>Email</ListItemText>
                </MenuItem>
                <MenuItem
                    disabled
                    // onClick={() => navigate("/whatsapp?isAll")}
                >
                    <ListItemIcon>
                        <WhatsAppIcon />
                    </ListItemIcon>
                    <ListItemText>Whatsapp</ListItemText>
                </MenuItem>
                <MenuItem onClick={() => navigate("/whatsapp?isAll")} disabled>
                    <ListItemIcon>
                        <CalendarIcon />
                    </ListItemIcon>
                    <ListItemText>Calendar</ListItemText>
                </MenuItem>
            </Menu>
        </>
    );
};
