import { useEffect, useMemo } from "react";
import { setWorkspaceId, setWorkspaceTab } from "../redux/uiSlice";
import { useAppDispatch } from "../redux/hooks";
import { getSubdomain, newSubdomainHref } from "../config/servers";
import { useGetWorkspacesQuery } from "../api/workspaceApi";

export const useWorkspaceDomain = () => {
    const dispatch = useAppDispatch();
    const subDomain = useMemo(() => getSubdomain(), []);
    const { data: workspaces, isLoading, isError } = useGetWorkspacesQuery();

    // if the subdomain is not empty,
    // set the workspaceId to the id of the workspaces object matching slug to subdomain; else redirect to the first workspace slug
    useEffect(() => {
        if (workspaces && workspaces.length > 0) {
            if (!subDomain) {
                console.log("redirecting to first workspace");
                window.location.href = newSubdomainHref(workspaces[0].slug);
                return;
            }
            const newTab = workspaces.findIndex((workspace) => workspace.slug === subDomain);

            if (newTab === -1) {
                dispatch(setWorkspaceId(workspaces[0].id));
                dispatch(setWorkspaceTab(0));
                window.location.href = newSubdomainHref(workspaces[0].slug);
            }

            if (newTab < 0) return;
            dispatch(setWorkspaceTab(newTab >= 0 ? newTab : 0));
            dispatch(setWorkspaceId(workspaces?.[newTab]?.id || workspaces[0].id));
        }
    }, [workspaces, dispatch, subDomain]);

    return { workspaces, isLoading, isError };
};
