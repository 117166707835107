import { Email as EmailIcon, WhatsApp as WhatsAppIcon } from "@mui/icons-material";
import { MessageType } from "../types/SharedTypes";

interface MessageTypeIconProps {
    messageType: MessageType;
}

export const MessageTypeIcon: React.FC<MessageTypeIconProps> = ({ messageType }) => {
    switch (messageType) {
        case "Email":
            return <EmailIcon fontSize="small" />;
        case "Whatsapp":
            return <WhatsAppIcon fontSize="small" />;
        default:
            return <EmailIcon fontSize="small" />;
    }
};
