import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { MADEON_API_SERVER } from "../config/servers";
import { PersonId, PersonContacts, Person } from "../types/PersonTypes";
import { WorkspaceId } from "../types/WorkspaceTypes";
import { getBearerTokenHeader } from "../utils/getToken";
const contentTypeJsonHeader = { "Content-Type": "application/json" };

interface GetPersonPayload {
    personId: PersonId;
    workspaceId: WorkspaceId;
}
interface PatchEmailPayload {
    workspaceId: WorkspaceId;
    personPatch: Partial<Person>;
}
interface GetPersonsPayload {
    workspaceId: WorkspaceId;
    page?: string;
}

export const personApi = createApi({
    reducerPath: "personApi",
    baseQuery: fetchBaseQuery({ baseUrl: MADEON_API_SERVER }),
    tagTypes: ["PersonContacts"],
    endpoints: (builder) => ({
        getPerson: builder.query<PersonContacts, GetPersonPayload>({
            query: ({ personId, workspaceId }) =>
                personId &&
                workspaceId && {
                    url: `/person/person-id/${personId}`,
                    headers: {
                        ...contentTypeJsonHeader,
                        ...getBearerTokenHeader(),
                        "x-workspace-id": workspaceId,
                    },
                },
            providesTags: (result) =>
                result
                    ? [
                          { type: "PersonContacts", id: result.id },
                          { type: "PersonContacts", id: "LIST" },
                      ]
                    : [{ type: "PersonContacts", id: "LIST" }],
        }),
        getPersons: builder.query<PersonContacts[], GetPersonsPayload>({
            query: ({ workspaceId, page }) =>
                workspaceId && {
                    url: `/person?${new URLSearchParams({ page } as any).toString()}`,
                    headers: {
                        ...contentTypeJsonHeader,
                        ...getBearerTokenHeader(),
                        "x-workspace-id": workspaceId,
                    },
                },
            providesTags: (result) =>
                result
                    ? result.map(({ id }) => ({
                          type: "PersonContacts" as const,
                          id,
                      }))
                    : [{ type: "PersonContacts", id: "LIST" }],
        }),
        patchPerson: builder.mutation<Person, PatchEmailPayload>({
            query: ({ workspaceId, personPatch }) =>
                workspaceId &&
                personPatch && {
                    url: `/person/person-id/${personPatch.id}`,
                    method: "PATCH",
                    body: personPatch,
                    headers: {
                        ...contentTypeJsonHeader,
                        ...getBearerTokenHeader(),
                        "x-workspace-id": workspaceId,
                    },
                },
            invalidatesTags: (result) =>
                result
                    ? [
                          { type: "PersonContacts", id: result.id },
                          { type: "PersonContacts", id: "LIST" },
                      ]
                    : [{ type: "PersonContacts", id: "LIST" }],
        }),
    }),
});

export const { useGetPersonQuery, useGetPersonsQuery, usePatchPersonMutation } = personApi;
