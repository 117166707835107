import { FetchBaseQueryError } from "@reduxjs/toolkit/query/react";
import { SerializedError } from "@reduxjs/toolkit";

// Function to cast sendError to a string
export function getRtkErrorMessage(sendError: FetchBaseQueryError | SerializedError): string {
    if ("status" in sendError) {
        // Handle FetchBaseQueryError
        switch (sendError.status) {
            case "FETCH_ERROR":
            case "PARSING_ERROR":
            case "CUSTOM_ERROR":
                return sendError.error;
            default:
                // Handle status as number
                return `Error with status code: ${sendError.status}`;
        }
    } else {
        // Handle SerializedError
        return sendError.message || "An unknown error occurred";
    }
}
