//https://stackoverflow.com/questions/63592567/material-ui-text-ellipsis-after-two-line

import { Box, SxProps } from "@mui/material";

interface LineClampProps {
    children?: React.ReactNode;
    lines: number;
    sx?: SxProps;
}

export const LineClamp: React.FC<LineClampProps> = ({ lines, children, sx }) => (
    <Box
        sx={{
            display: "-webkit-box",
            WebkitLineClamp: lines,
            WebkitBoxOrient: "vertical",
            overflow: "hidden",
            ...sx,
        }}
    >
        {children}
    </Box>
);
