import { useEffect, useState } from "react";
import { usePage } from "./usePage";
import { GridPaginationModel } from "@mui/x-data-grid";

export const useMuiGridPagination = () => {
    const { page, handlePageChange } = usePage();

    const [paginationModel, setPaginationModel] = useState({ page, pageSize: 20 });

    const handlePageChageEvent = (model: GridPaginationModel) => handlePageChange(model.page);

    useEffect(() => {
        setPaginationModel({ page, pageSize: 20 });
    }, [page]);

    return { paginationModel, handlePageChageEvent };
};
