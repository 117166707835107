import { Autocomplete } from "@mui/material";
import { useSearchPersonQuery } from "../api/searchApi";
import { useEffect, useState } from "react";
import { useDebounce } from "../hooks/useDebounce";
import { validEmail } from "../utils/validEmail";
import { ResponseActionButtonsOptionIndex } from "./ResponseActionButtons";
import { useAppSelector, useAppDispatch } from "../redux/hooks";
import { EmailContact, blankEmailContact } from "../types/EmailTypes";
import { setDraftEmailToRecipients } from "../redux/uiSlice";
import { CustomInput, CustomOption, CustomTags } from "./PersonInputComponents";
import { PERSON_INPUT_DEBOUNCE_TIME_MS } from "../config/constants";

export interface AutocompleteOption {
    label: string;
    emailContact: EmailContact;
}

export interface PersonInputProps {
    selectedIndex: ResponseActionButtonsOptionIndex;
    setSelectedIndex: React.Dispatch<React.SetStateAction<ResponseActionButtonsOptionIndex>>;
}

export const PersonInput: React.FC<PersonInputProps> = (props) => {
    const [inputValue, setInputValue] = useState<string>("");
    const [value, setValue] = useState<AutocompleteOption[]>([]);
    const debouncedInputValue = useDebounce(inputValue, PERSON_INPUT_DEBOUNCE_TIME_MS);
    const [options, setOptions] = useState<AutocompleteOption[]>([]);
    const workspaceId = useAppSelector((state) => state.ui.workspaceId);
    const { data: persons, isLoading } = useSearchPersonQuery(
        {
            queryString: debouncedInputValue,
            workspaceId,
        },
        { skip: !workspaceId || !debouncedInputValue }
    );
    const draftEmail = useAppSelector((state) => state.ui?.draftEmail || null);

    const dispatch = useAppDispatch();

    // Pull in recipients from draft email and set them as the value which will be displayed in the input as chips
    useEffect(() => {
        if (!draftEmail) return;
        const { to, cc, bcc } = draftEmail;
        const recipientsFlat: EmailContact[] = [...to, ...cc, ...bcc];

        setValue(
            recipientsFlat.map((recipient) => ({
                label: recipient.name,
                emailContact: recipient,
            }))
        );
    }, [draftEmail]);

    // Set the options for the autocomplete based on the persons returned from the search query
    useEffect(() => {
        if (!persons) return;

        const flatPersons: AutocompleteOption[] = [];
        persons.forEach((person) => {
            person.emailAddresses.forEach((email) => {
                flatPersons.push({
                    label: `${person.fullName} (${email})`,
                    emailContact: { name: person.fullName, emailAddress: email, people: [] },
                });
            });
        });

        // Add add email option if the input value is a valid email
        if (inputValue.length > 0 && validEmail(inputValue)) {
            flatPersons.push({
                label: `Add email : ${inputValue}`,
                emailContact: {
                    ...blankEmailContact,
                    name: inputValue,
                    emailAddress: inputValue,
                },
            });
        }

        setOptions(flatPersons);
    }, [persons, inputValue]);

    const handleChange = (
        event: React.SyntheticEvent<Element, Event>,
        newValue: readonly AutocompleteOption[]
    ) => {
        if (newValue) {
            let mutableNewValue = [...newValue];

            // TODO:  Figure out what this code is doing and revise
            // const newEmailContactIndex = newValue.findIndex(
            //     (recipient) => recipient.id === "contactId-create-new"
            // );

            // if (newEmailContactIndex >= 0) {
            //     const newEmailContact: AutocompleteOption = newValue[newEmailContactIndex];
            //     mutableNewValue[newEmailContactIndex].label = newEmailContact.label.substring(12);
            //     mutableNewValue[
            //         newEmailContactIndex
            //     ].id = `contactId-create-${newEmailContact.label.substring(12)}`;
            // }

            // Clears value so that dispatch-effect can set Value from scratch
            setValue([]);

            // Warning:  This moves all the email recipients from CC to TO
            dispatch(setDraftEmailToRecipients(mutableNewValue.map((recipient) => recipient.emailContact)));
        }
        return;
    };

    return (
        <Autocomplete
            value={value}
            onChange={handleChange}
            inputValue={inputValue}
            onInputChange={(event, newInputValue) => setInputValue(newInputValue)}
            options={options}
            getOptionLabel={(option) => option.label}
            multiple
            renderTags={(value, getTagProps) => <CustomTags value={value} getTagProps={getTagProps} />}
            renderInput={(params) => <CustomInput params={params} isLoading={isLoading} {...props} />}
            renderOption={(props, recipient) => <CustomOption props={props} recipient={recipient} />}
        />
    );
};
