type Environment = "development" | "production" | "staging";

export const AUTH_ENDPOINT = "https://auth.maedeon.com/oauth2/authorize";
export const TOKEN_ENDPOINT = "https://auth.maedeon.com/oauth2/token";

export const ENVIRONMENT: Environment = (() => {
    const hostname = window.location.hostname;
    switch (true) {
        case hostname.includes("localhost"):
            return "development";
        case hostname.includes("internal.maedeon.com"):
            return "staging";
        default:
            return "production";
    }
})();

export const DOMAIN = ((environment: Environment) => {
    switch (environment) {
        case "development":
            return "app.localhost";
        case "staging":
            const parts = window.location.hostname.split(".");
            return parts.slice(parts.indexOf("internal") - 1).join(".");
        default:
            return "app.maedeon.com";
    }
})(ENVIRONMENT);

// export const MADEON_API_SERVER =
//     ENVIRONMENT === "development" ? "http://localhost:8081/api/v1/" : `https://api.${DOMAIN}/api/v1`;

export const MADEON_API_SERVER = ((environment: Environment) => {
    switch (environment) {
        case "development":
            return "http://localhost:8081/api/v1/";
        case "staging":
            return `https://api.${DOMAIN}/api/v1`;
        default:
            return "https://api.maedeon.com/api/v1/";
    }
})(ENVIRONMENT);

export const LOGIN_URL = `${window.location.protocol}//${DOMAIN}${
    window.location.port && `:${window.location.port}`
}/login`;

export const getSubdomain = () => {
    const subdomain = window.location.hostname.split(".")[0];
    return subdomain !== DOMAIN.split(".")[0] ? subdomain : "";
};

export const newSubdomainHref = (subdomain: string) =>
    `${window.location.protocol}//${subdomain}.${DOMAIN}${
        window.location.port ? `:${window.location.port}` : ""
    }`;

export const SIMPLE_FILE_UPLOAD_SERVER = "http://localhost:5000/upload";
