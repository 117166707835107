import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import type { Priority } from "../types/PriorityTypes";
import type { WorkspaceId } from "../types/WorkspaceTypes";
import { MADEON_API_SERVER } from "../config/servers";
import { getBearerTokenHeader } from "../utils/getToken";
const contentTypeJsonHeader = { "Content-Type": "application/json" };

export const priorityApi = createApi({
    reducerPath: "priorityApi",
    baseQuery: fetchBaseQuery({ baseUrl: MADEON_API_SERVER }),
    tagTypes: ["Priority"],
    endpoints: (builder) => ({
        getPriority: builder.query<Priority[], WorkspaceId>({
            query: (workspaceId) => ({
                url: `/priority`,
                headers: {
                    ...contentTypeJsonHeader,
                    ...getBearerTokenHeader(),
                    "x-workspace-id": workspaceId,
                },
            }),
            providesTags: (result) =>
                result
                    ? [
                          ...result.map((priority) => ({
                              type: "Priority" as const,
                              id: priority.PriorityId,
                          })),
                          { type: "Priority", id: "LIST" },
                      ]
                    : [{ type: "Priority", id: "LIST" }],
        }),
    }),
});

export const { useGetPriorityQuery, useLazyGetPriorityQuery } = priorityApi;
