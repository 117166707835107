import { DOMAIN, LOGIN_URL, AUTH_ENDPOINT, TOKEN_ENDPOINT } from "../config/servers";
import { TAuthConfig } from "../react-oauth2-code-pkce";
export const config: TAuthConfig = {
    clientId: "public-client",
    authorizationEndpoint: AUTH_ENDPOINT,
    tokenEndpoint: TOKEN_ENDPOINT,
    redirectUri: LOGIN_URL,
    onRefreshTokenExpire: (event) => {
        window.confirm("Tokens have expired. Login again to continue");
    },
    // Example to redirect back to original path after login has completed
    // preLogin: () => localStorage.setItem("preLoginPath", window.location.pathname),
    // postLogin: () => window.location.replace(localStorage.getItem("preLoginPath") || ""),
    decodeToken: true,
    scope: "openid profile",
    autoLogin: false,
    storage: "cookie",
    baseDomain: DOMAIN,
    storageKeyPrefix: "MADEON_AGENT_",
};
