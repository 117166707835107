import { Box, Typography, LinearProgress } from "@mui/material";

export const LoadingComponent: React.FC<{}> = () => {
    return (
        <Box>
            <LinearProgress />
        </Box>
    );
};

export const ErrorComponent: React.FC<{}> = () => {
    return (
        <Box>
            <Typography variant="h2">Oh no, there was an error</Typography>
        </Box>
    );
};

export const NoDataComponent: React.FC<{}> = () => {
    return (
        <Box>
            <Typography variant="h2">No data</Typography>
        </Box>
    );
};

export const StatusHelper: React.FC<{ isLoading: boolean; error: boolean; data: boolean }> = ({
    isLoading,
    error,
    data,
}) => {
    if (isLoading) {
        return <LoadingComponent />;
    }
    if (error) {
        return <ErrorComponent />;
    }

    if (!data) {
        return <NoDataComponent />;
    }
    return <Box>unknown state</Box>;
};
