import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import type { Thread, ThreadId, ThreadFilter, ThreadFilterOptional } from "../types/ThreadTypes";
import type { WorkspaceId } from "../types/WorkspaceTypes";
import { MADEON_API_SERVER } from "../config/servers";
import { getBearerTokenHeader } from "../utils/getToken";
const contentTypeJsonHeader = { "Content-Type": "application/json" };

interface GetThreadPayload {
    threadId: ThreadId;
    workspaceId: WorkspaceId;
}

interface PatchThreadsPayload {
    threadIds: ThreadId[];
    workspaceId: WorkspaceId;
}
interface PatchThreadsResponse {
    id: ThreadId;
}

export const threadApi = createApi({
    reducerPath: "threadApi",
    baseQuery: fetchBaseQuery({ baseUrl: MADEON_API_SERVER }),
    tagTypes: ["Thread"],
    endpoints: (builder) => ({
        getThread: builder.query<Thread, GetThreadPayload>({
            query: ({ threadId, workspaceId }) =>
                threadId &&
                workspaceId && {
                    url: `/thread/thread-id/${threadId}`,
                    headers: {
                        ...contentTypeJsonHeader,
                        ...getBearerTokenHeader(),
                        "x-workspace-id": workspaceId,
                    },
                },
            providesTags: (result) =>
                result
                    ? [
                          { type: "Thread", id: result.id },
                          { type: "Thread", id: "LIST" },
                      ]
                    : [{ type: "Thread", id: "LIST" }],
        }),
        getThreads: builder.query<Thread[], ThreadFilter>({
            query: (threadFilter) => {
                var cleanedFilter = {
                    page: threadFilter.page,
                } as ThreadFilterOptional;

                if (threadFilter.startDate) {
                    cleanedFilter.startDate = threadFilter.startDate;
                }

                if (threadFilter.endDate) {
                    cleanedFilter.endDate = threadFilter.endDate;
                }

                return (
                    threadFilter && {
                        url: `/thread${
                            threadFilter?.personId ? "/person-id/" + threadFilter.personId : ""
                        }?${new URLSearchParams(cleanedFilter as any).toString()}`,
                        method: "GET",
                        headers: {
                            ...contentTypeJsonHeader,
                            ...getBearerTokenHeader(),
                            "x-workspace-id": threadFilter.workspaceId,
                        },
                    }
                );
            },
            providesTags: (result) => {
                return result
                    ? [
                          ...result.map(({ id }) => ({ type: "Thread" as const, id })),
                          { type: "Thread", id: "LIST" },
                      ]
                    : [{ type: "Thread", id: "LIST" }];
            },
        }),
        patchThreads: builder.mutation<PatchThreadsResponse[], PatchThreadsPayload>({
            query: ({ threadIds, workspaceId }) => ({
                url: "/thread/archive",
                method: "PATCH",
                body: threadIds,
                headers: {
                    ...contentTypeJsonHeader,
                    ...getBearerTokenHeader(),
                    "x-workspace-id": workspaceId,
                },
            }),
            invalidatesTags: (result) => (result ? result.map((a) => ({ type: "Thread", id: a.id })) : []),
        }),
    }),
});

export const { useGetThreadQuery, useGetThreadsQuery, usePatchThreadsMutation } = threadApi;
