import { Icon, Box, Typography, IconButton } from "@mui/material";
import { ChevronRight, ChevronLeft } from "@mui/icons-material";
import { useSearchParams, useNavigate } from "react-router-dom";
import { updatedSearchParams } from "../utils/updateSearchParams";
import { usePage } from "../hooks/usePage";
import { useTheme } from "@mui/material/styles";
import { THREADS_PER_PAGE } from "../config/constants";
import { useThreadsListData } from "./ThreadList";

export const Pagination: React.FC<{}> = () => {
    const [searchParams] = useSearchParams();
    const noPagination = searchParams.get("noPagination") === "" ? true : false;
    const { page } = usePage();
    const theme = useTheme();
    const navigate = useNavigate();

    const { threads } = useThreadsListData();

    if (noPagination) return null;
    return (
        <Box mt={1} mb={1} textAlign="center">
            <Box display="inline-block" mr={2}>
                <Typography variant="caption" sx={{ color: theme.palette.grey[700] }}>
                    {`${(page || 0) * THREADS_PER_PAGE + 1}-${((page || 0) + 1) * THREADS_PER_PAGE}`} of Many
                </Typography>
            </Box>
            <IconButton
                disabled={page === 0}
                onClick={() =>
                    navigate(`${updatedSearchParams(searchParams, { page: (page - 1).toString() })}`)
                }
            >
                <Icon>
                    <ChevronLeft />
                </Icon>
            </IconButton>
            <IconButton
                onClick={() =>
                    navigate(`${updatedSearchParams(searchParams, { page: (page + 1).toString() })}`)
                }
                disabled={(threads || []).length < THREADS_PER_PAGE}
            >
                <Icon>
                    <ChevronRight />
                </Icon>
            </IconButton>
        </Box>
    );
};
