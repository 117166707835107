import axios from "axios";
import { MADEON_API_SERVER } from "../config/servers";

export const s3FileUpload = async (file: File, token: string, workspaceId: string) => {
    if (file) {
        try {
            const res1 = await axios.post(
                `${MADEON_API_SERVER}email/attachment/put-url`,
                { fileName: file.name, contentType: file.type },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        "x-workspace-id": workspaceId,
                        "Content-Type": "application/json",
                    },
                }
            );
            if (res1.status !== 200) throw new Error("Error getting upload url");

            const { putUrl, getUrl }: { putUrl: string; getUrl: string } = res1.data;

            const res2 = await axios.put(putUrl, file, {
                headers: {
                    "Content-Type": "application/octet-stream",
                },
                onUploadProgress: (e) => {
                    console.log(e);
                },
            });

            if (res2.status !== 200) throw new Error("Error uploading file");

            return { default: getUrl };
        } catch (err) {
            console.log(err);
        }
    }
    throw new Error("Error in client app");
};
