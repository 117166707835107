import { useMatches } from "react-router-dom";
import { ChevronRight } from "@mui/icons-material";
import { Link as RouterLink } from "react-router-dom";
import { ReactNode } from "react";
import { Link as MUILink, Box } from "@mui/material";
import { useTheme } from "@mui/material/styles";

export const Breadcrumbs = () => {
    const matches = useMatches();
    const theme = useTheme();
    const crumbs = matches
        .filter((match) => Boolean((match.handle as any)?.crumb))
        .map((match) =>
            (match.handle as any).crumb({
                data: match.data,
                params: match.params,
                pathname: match.pathname,
                id: match.id,
            })
        );

    return crumbs.length > 0 ? (
        <Box m={2} color={theme.palette.grey[700]}>
            <Box display="flex" alignItems="center">
                {crumbs.map((crumb, i) => (
                    <Box key={i} display="flex" alignItems="center">
                        {crumb}
                        {i !== crumbs.length - 1 && (
                        <NudgeIcon>
                            <ChevronRight />
                        </NudgeIcon>
                        )}
                    </Box>
                ))}
            </Box>
        </Box>
    ) : null;
};

export const NudgeIcon: React.FC<{ children: ReactNode; props?: any }> = ({ children, ...props }) => (
    <Box position="relative" top="3px" mx={0.5} {...props}>
        {children}
    </Box>
);

const Link = (props: any) => <MUILink underline="hover" {...props} />;

const NavLink: React.FC<{ to: string; children: ReactNode }> = ({ to, children }) =>
    to === (window.location.pathname+window.location.search) ? (
        <Box>{children}</Box>
    ) : (
        <Link component={RouterLink} to={to}>
            {children}
        </Link>
    );

export const BreadCrumb: React.FC<{ to: string; icon: ReactNode; crumbText: string }> = ({
    to,
    icon,
    crumbText,
}) => (
    <NavLink to={to}>
        <Box display="flex" alignItems="center">
            <NudgeIcon>{icon}</NudgeIcon>
            <Box>{crumbText}</Box>
        </Box>
    </NavLink>
);
