export const ALL_PRIORITIES_ID = "all-priorities";
export const PERSON_INPUT_DEBOUNCE_TIME_MS = 500;

// This is a development environment workaround.  When changing routes, RTK query correctly refreshes the workspaces and so
// the workspace that is clicked to no longer exists in the store.  this causes ui.workspace to be undefined causing some errors
// this flag is used to prevent the errors from happening.  This should be removed when a real api is developed
export const DEV_MOCKSERVER_WORKAROUND = false;

export const POPUP_HEIGHT = 700;
export const POPUP_WIDTH = 600;
export const POPUP_CLOSE_TIMEOUT = 1000;

export const NO_AUTH = false;

export const THREADS_PER_PAGE = 20;

export const APPBAR_HEIGHT = 68;
export const THREADS_TOOLBAR_HEIGHT = 56;
export const THREAD_TOOLBAR_HEIGHT = 44;
