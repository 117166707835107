import {
    Box,
    TextField,
    CircularProgress,
    InputAdornment,
    Chip,
    // Avatar,
    AutocompleteGetTagProps,
    AutocompleteRenderInputParams,
} from "@mui/material";
// import { PersonAddAlt1 as PersonAddIcon } from "@mui/icons-material";
// import { useTheme } from "@mui/material/styles";
import { ResponseActionButtonsInline } from "./ResponseActionButtons";
import { AutocompleteOption, PersonInputProps } from "./PersonInput";

interface CustomTagsProps {
    value: AutocompleteOption[];
    getTagProps: AutocompleteGetTagProps;
}

interface CustomInputProps extends PersonInputProps {
    params: AutocompleteRenderInputParams;
    isLoading?: boolean;
}

interface CustomOptionProps {
    props: React.HTMLAttributes<HTMLLIElement>;
    recipient: AutocompleteOption;
}

export const CustomTags: React.FC<CustomTagsProps> = ({ value, getTagProps }) => (
    <>
        {value.map((option, index) => (
            <Chip
                label={option.emailContact.name + " <" + option.emailContact.emailAddress + ">"}
                {...getTagProps({ index })}

                // TODO: Bring back avatar
                // avatar={
                //     <Avatar src={option.emailContact.Avatar}>
                //         {option.emailContact.Name.substring(0, 1).toUpperCase()}
                //     </Avatar>
                // }
            />
        ))}
    </>
);

export const CustomInput: React.FC<CustomInputProps> = ({
    params,
    selectedIndex,
    setSelectedIndex,
    isLoading,
}) => (
    <TextField
        {...params}
        InputProps={{
            ...params.InputProps,
            startAdornment: (
                <>
                    <InputAdornment position="start">
                        <ResponseActionButtonsInline
                            selectedIndex={selectedIndex}
                            setSelectedIndex={setSelectedIndex}
                        />
                    </InputAdornment>
                    {params.InputProps.startAdornment}
                </>
            ),
            endAdornment: (
                <>
                    {isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                    {params.InputProps.endAdornment}
                </>
            ),
        }}
    />
);

export const CustomOption: React.FC<CustomOptionProps> = ({ props, recipient }) => {
    // const theme = useTheme();
    return (
        <li {...props}>
            <Box display="flex" alignItems="center">
                <Box mr={1}>
                    {/* TODO:  Bring back avatar */}
                    {/* <Avatar
                        sx={{ width: theme.spacing(3), height: theme.spacing(3) }}
                        src={recipient.emailContact.Avatar}
                    >
                        {recipient.label.includes("Add email : ") ? (
                            <PersonAddIcon sx={{ width: theme.spacing(2.2), height: theme.spacing(2.2) }} />
                        ) : (
                            recipient.label.substring(0, 1)
                        )}
                    </Avatar> */}
                </Box>
                <Box>{recipient.emailContact.name + " <" + recipient.emailContact.emailAddress + ">"}</Box>
            </Box>
        </li>
    );
};
