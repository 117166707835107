import { Button, Card, CardMedia, CardActionArea, Box, Typography } from "@mui/material";
import { Attachment } from "../types/EmailTypes";
import {
    CloudDownload as CloudDownloadIcon,
    Delete as DeleteIcon,
    Description as DescriptionIcon,
    PictureAsPdf as PictureAsPdfIcon,
    Slideshow as SlideshowIcon,
    TableChart as TableChartIcon,
    Compress as ZipIcon,
    TextSnippet as TxtIcon,
    Archive as TarGzIcon,
    InsertDriveFile as InsertDriveFileIcon,
} from "@mui/icons-material";
import { LineClamp } from "./LineClamp";
import { removeDraftEmailAttachment } from "../redux/uiSlice";
import { useAppDispatch } from "../redux/hooks";

type AttachmentListActionType = "download" | "delete";

interface AttachmentsListProps {
    attachments: Attachment[];
    actionType: AttachmentListActionType;
}

interface AttachmentThumbnailProps {
    attachment: Attachment;
    actionType: AttachmentListActionType;
}

export const AttachmentsList: React.FC<AttachmentsListProps> = ({ attachments, actionType }) => {
    return attachments.length > 0 ? (
        <Box>
            {/* <hr /> */}
            <Typography variant="caption">
                {attachments.length} attachment
                {attachments.length > 1 && "s"}
            </Typography>
            <Box mt={1} display="flex">
                {attachments.map((attachment, index) => (
                    <AttachmentThumbnail
                        attachment={attachment}
                        key={`attachment-${index}`}
                        actionType={actionType}
                    />
                ))}
            </Box>
        </Box>
    ) : null;
};

export const AttachmentThumbnail: React.FC<AttachmentThumbnailProps> = ({
    attachment,
    actionType = "download",
}) => {
    const dispatch = useAppDispatch();
    const { name, url } = attachment;

    // Extract file extension
    const fileExtension = name.split(".").pop()?.toLowerCase() || "";

    const isImage = ["jpg", "jpeg", "png", "gif", "bmp", "webp"].includes(fileExtension);

    const getIconForFile = (fileExtension: string) => {
        const iconProps = { sx: { fontSize: 80, color: "primary.main" } };

        switch (fileExtension) {
            case "pdf":
                return <PictureAsPdfIcon {...iconProps} />;
            case "doc":
            case "docx":
                return <DescriptionIcon {...iconProps} />;
            case "ppt":
            case "pptx":
                return <SlideshowIcon {...iconProps} />;
            case "xls":
            case "xlsx":
                return <TableChartIcon {...iconProps} />;
            case "zip":
                return <ZipIcon {...iconProps} />;
            case "txt":
                return <TxtIcon {...iconProps} />;
            case "tar.gz":
                return <TarGzIcon {...iconProps} />;
            default:
                return <InsertDriveFileIcon {...iconProps} />;
        }
    };

    const handleRemoveAttachment = (attachment: Attachment) =>
        dispatch(removeDraftEmailAttachment(attachment));

    const handleDownloadAttachment = (attachment: Attachment, filename?: string): void => {
        const { url } = attachment;
        // Create an anchor element
        const a = document.createElement("a");

        // Set the href to the provided URL
        a.href = url;

        // Set the "target" attribute to "_blank" to open in a new tab/window
        // (but since it's a download, no new tab/window should actually appear)
        a.target = "_blank";

        // If a filename is provided, set it as the download attribute of the anchor
        if (filename) {
            a.download = filename;
        } else {
            // If no filename is provided, try to extract it from the URL
            a.download = url?.split("/")?.pop() || "";
        }

        // Append the anchor to the body (this is necessary for Firefox)
        document.body.appendChild(a);

        // Trigger the download by simulating a click on the anchor
        a.click();

        // Clean up by removing the anchor from the body
        document.body.removeChild(a);
    };

    const clickHandler = actionType === "download" ? handleDownloadAttachment : handleRemoveAttachment;

    return (
        <Box mr={1}>
            <Card
                variant="outlined"
                style={{ width: 180, height: 120 }}
                sx={{ ":hover": { cursor: "pointer" } }}
                onClick={() => clickHandler(attachment)}
            >
                <CardActionArea>
                    {/* Add Button with Cloud Download Icon on the top right corner of the card */}
                    <Box position="absolute" top={0} right={0} m={1}>
                        <Button
                            component="div"
                            variant="contained"
                            size="small"
                            sx={{ minWidth: 0, padding: 0.5 }}
                            color={actionType === "download" ? "primary" : "error"}
                        >
                            {actionType === "download" ? (
                                <CloudDownloadIcon sx={{ fontSize: 12 }} />
                            ) : (
                                <DeleteIcon sx={{ fontSize: 12 }} />
                            )}
                        </Button>
                    </Box>
                    {isImage ? (
                        <>
                            <CardMedia component="img" alt={name} height="120" image={url} title={name} />
                            <Box
                                position="absolute"
                                bottom={0}
                                left={0}
                                right={0}
                                bgcolor="rgba(0, 0, 0, 0.5)"
                                color="white"
                                p={0.5}
                            >
                                <LineClamp lines={1}>{name}</LineClamp>
                            </Box>
                        </>
                    ) : (
                        <>
                            <Box height={120} textAlign="center">
                                {getIconForFile(fileExtension)}
                            </Box>
                            <Box
                                position="absolute"
                                bottom={0}
                                left={0}
                                right={0}
                                bgcolor="rgba(0, 0, 0, 0.5)"
                                color="white"
                                p={0.5}
                            >
                                <LineClamp lines={1}>{name}</LineClamp>
                            </Box>
                        </>
                    )}
                </CardActionArea>
            </Card>
        </Box>
    );
};
