import React from "react";
import { Box, IconButton, CircularProgress, Tooltip } from "@mui/material";
import { ArrowBack, Archive, Delete, AddCircleOutline } from "@mui/icons-material";
import { useTheme } from "@mui/material/styles";
import { useNavigate, useParams } from "react-router-dom";
import { usePatchThreadsMutation } from "../api/threadApi";
import { useAppSelector } from "../redux/hooks";
import { ThreadId } from "../types/ThreadTypes";

export interface ThreadButtonGroupProps {}

export const ThreadButtonGroup: React.FC<ThreadButtonGroupProps> = () => {
    const theme = useTheme();
    const navigate = useNavigate();
    const params = useParams<{ threadId: string }>();
    const threadId: ThreadId = params.threadId || "";
    const workspaceId: string = useAppSelector((state) => state.ui.workspaceId);

    const [patchThreadsMutation, { isLoading: isArchiveLoading }] = usePatchThreadsMutation();

    return (
        <Box m={1}>
            <Box display="flex" width={theme.spacing(20)} ml={-0.4} justifyContent="space-evenly">
                <IconButton onClick={() => navigate(-1)}>
                    <ArrowBack fontSize="small" />
                </IconButton>

                <IconButton
                    onClick={async () => {
                        await patchThreadsMutation({ workspaceId, threadIds: [threadId] });
                        navigate(-1);
                    }}
                >
                    {isArchiveLoading ? (
                        <CircularProgress size="1.3rem" />
                    ) : (
                        <Tooltip title="Archive">
                            <Archive fontSize="small" />
                        </Tooltip>
                    )}
                </IconButton>

                <IconButton disabled>
                    <Tooltip title="Delete">
                        <Delete fontSize="small" />
                    </Tooltip>
                </IconButton>
                <IconButton onClick={() => navigate("/email/compose")}>
                    <Tooltip title="Compose new email">
                        <AddCircleOutline fontSize="small" />
                    </Tooltip>
                </IconButton>
            </Box>
        </Box>
    );
};
