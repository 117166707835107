import React, { useEffect, useCallback } from "react";
import { ThreadId } from "../types/ThreadTypes";
import { Box, Typography } from "@mui/material";
import { useGetThreadQuery } from "../api/threadApi";
import { Email } from "./Email";
import { useParams } from "react-router-dom";
import { StatusHelper } from "./StatusComponents";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { setCurrentMessageId } from "../redux/uiSlice";
import { useGetEmailByThreadIdQuery } from "../api/emailApi";
import { APPBAR_HEIGHT, THREAD_TOOLBAR_HEIGHT } from "../config/constants";

interface ThreadComponentProps {
    threadIdProp?: ThreadId;
}

export const ThreadComponent: React.FC<ThreadComponentProps> = ({ threadIdProp }) => {
    const [refTrigger, setRefTrigger] = React.useState(false);
    const workspaceId = useAppSelector((state) => state.ui.workspaceId);
    const params = useParams<{ threadId: string; emailId: string | undefined }>();
    const threadId: string = threadIdProp || params.threadId || "";
    const dispatch = useAppDispatch();
    const {
        data: thread,
        error,
        isLoading,
    } = useGetThreadQuery({ threadId, workspaceId }, { skip: !threadId || !workspaceId });

    const {
        data: emailSnippets,
        error: errorSnippets,
        isLoading: isLoadingSnippets,
    } = useGetEmailByThreadIdQuery({ threadId, workspaceId }, { skip: !threadId });

    const setRef = useCallback((node: HTMLDivElement | null) => setRefTrigger(true), []);
    const initExpanded = (emailSnippetId: string, idx: number) =>
        params?.emailId
            ? params.emailId === emailSnippetId
            : emailSnippets && idx === emailSnippets.length - 1;

    useEffect(() => {
        if (emailSnippets && emailSnippets.length > 0) {
            dispatch(setCurrentMessageId(emailSnippets[emailSnippets.length - 1]?.id || ""));
        }
    }, [emailSnippets, dispatch]);

    // triggered when reference to an expanded email is set
    // scrolls the viewport to the expanded email
    useEffect(() => {
        const viewport = document.getElementById("thread-component-viewport");
        const selectedEmail = document.getElementById("selected-email");
        if (viewport && selectedEmail) {
            const selectedEmailRect = selectedEmail.getBoundingClientRect();
            const viewportRect = viewport.getBoundingClientRect();
            const scrollTo = selectedEmailRect.top - viewportRect.top;

            viewport.scrollTo({
                top: scrollTo,
                behavior: "auto",
            });
        }
    }, [refTrigger]);

    if (isLoading || error || !thread || !threadId || !emailSnippets || isLoadingSnippets || errorSnippets)
        return (
            <StatusHelper
                isLoading={!!isLoading || !!isLoadingSnippets}
                error={!!error || !!errorSnippets}
                data={!!threadId && !!thread && !!emailSnippets}
            />
        );

    return (
        // added overflowY: "scroll" and height: `calc(100vh - ${APPBAR_HEIGHT + THREAD_TOOLBAR_HEIGHT}px)` to the outer Box component
        // to allow the component to scroll in the viewport while keeping the app bar and thread toolbar fixed
        <Box
            sx={{ height: `calc(100vh - ${APPBAR_HEIGHT + THREAD_TOOLBAR_HEIGHT}px)`, overflowY: "scroll" }}
            id="thread-component-viewport"
        >
            <Box pt={2} pb={2} ml={2}>
                <Typography variant="h6" component="h1">
                    {thread.subject}
                </Typography>
            </Box>
            <Box>
                {emailSnippets &&
                    emailSnippets.map(
                        (emailSnippet, idx) =>
                            emailSnippet?.id && (
                                <Box
                                    ref={initExpanded(emailSnippet.id, idx) ? setRef : null}
                                    id={initExpanded(emailSnippet.id, idx) ? "selected-email" : ""}
                                    key={emailSnippet.id}
                                >
                                    <Email
                                        id={emailSnippet.id}
                                        initiallyExpanded={initExpanded(emailSnippet.id, idx)}
                                        isLast={idx === emailSnippets.length - 1}
                                        emailSnippet={emailSnippet}
                                    />
                                </Box>
                            )
                    )}
            </Box>
        </Box>
    );
};
