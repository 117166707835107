import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { MADEON_API_SERVER } from "../config/servers";
import { PersonContacts } from "../types/PersonTypes";
import { WorkspaceId } from "../types/WorkspaceTypes";
import { getBearerTokenHeader } from "../utils/getToken";
const contentTypeJsonHeader = { "Content-Type": "application/json" };

interface SearchPersonPayload {
    queryString: string;
    workspaceId: WorkspaceId;
}

export const searchApi = createApi({
    reducerPath: "searchApi",
    baseQuery: fetchBaseQuery({ baseUrl: MADEON_API_SERVER }),
    tagTypes: ["PersonContacts"],
    endpoints: (builder) => ({
        searchPerson: builder.query<PersonContacts[], SearchPersonPayload>({
            query: ({ queryString, workspaceId }) => ({
                url: `/search/person-contact?query=${encodeURIComponent(queryString)}`,
                headers: {
                    ...contentTypeJsonHeader,
                    ...getBearerTokenHeader(),
                    "x-workspace-id": workspaceId,
                },
            }),
            providesTags: (result) =>
                result
                    ? [
                          ...result.map((Person) => ({
                              type: "PersonContacts" as const,
                              id: Person.id,
                          })),
                          { type: "PersonContacts", id: "LIST" },
                      ]
                    : [{ type: "PersonContacts", id: "LIST" }],
        }),
    }),
});

export const { useSearchPersonQuery } = searchApi;
